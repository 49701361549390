'use client'
import { XCircleIcon } from "@heroicons/react/24/outline";

interface Props{
    message: string,
    severity: number
}

const AlertMessage: React.FC<Props> = ({message, severity}) => {

    return <><div className={`rounded-sm mt-2 ${
                severity === 0 && 'bg-green-200' ||  severity === 1 && 'bg-yellow-200' || severity === 2 && 'bg-red-200'}`}>
            <h3 className={`text-sm font-medium ${
                severity === 0 && 'text-green-900' ||  severity === 1 && 'text-yellow-900' || severity === 2 && 'text-red-900'}`}>{message}</h3>
</div></>
}

export default AlertMessage;