import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface globalLoadingState{
    loading: boolean
}

const initialState: globalLoadingState = {
    loading: false
}

const loadingSlice = createSlice({
    name: "loading",
    initialState,
    reducers: {
        updateLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        }
    }
});

export const { updateLoading } = loadingSlice.actions;

export default loadingSlice.reducer;