/* eslint-disable react-hooks/rules-of-hooks */
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../redux/store";
import { Dialog } from "@headlessui/react";
import { useState } from "react";
import UploadImageInit from "../../setup/upload-image";
import { JournalistService } from "../../../../services/journalistService";
import { IBaseResult } from "../../../../models/baseResults";
import { IJournalistModel } from "../../../../models/content-models/journalistModels";
import { updateJournalist } from "../../../../redux/slice/journalistSlice";

const profile = () => {
  const [openImage, setOpenImage] = useState(false);
  const [openByline, setOpenByline] = useState(false);

  const journalistService = new JournalistService();

  const journalist = useSelector(
    (state: RootState) => state.journalist?.journalist
  );
  const dispatch = useDispatch();

  const [byline, setByline] = useState(journalist?.byline);

  const updateImage = (e: any) => {};

  const updateByLine = async (e: any) => {
    let journalistUpdate = {
      cognitoID: journalist.cognitoID,
      journalistID: journalist.journalistID,
      email: journalist.email,
      firstName: journalist.firstName,
      lastName: journalist.lastName,
      middle: journalist.middle,
      expertiseCategories: journalist.expertiseCategories,
      sources: journalist.sources,
      roles: journalist.roles,
      byline: byline,
      image: journalist.image,
      phoneNumber: journalist.phoneNumber,
    };

    await journalistService
      .putJournalist(journalistUpdate, journalist?.journalistID)
      .then(async (response: IBaseResult<boolean>) => {
        if (response.isSuccessful) {
          setOpenByline(false);

          await journalistService
            .getJournalist("JournalistID", journalist?.journalistID)
            .then((response: IBaseResult<IJournalistModel>) => {
              if (response.isSuccessful) {
                dispatch(updateJournalist(response.result));
              }
            });
        } else {
        }
      });

    // await journalistService.getJournalist("JournalistID", journalist.journalistID)
  };

  const handleBylineChange = () => {
    setOpenByline(true);
  };

  const handleProfileImageChange = () => {
    setOpenImage(true);
  };

  const bylineModal = () => {
    return (
      <>
        <Dialog
          open={openByline}
          onClose={setOpenByline}
          className="relative z-10"
        >
          <div className="bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in" />
          <div className="fixed inset-0 z-10 items-center justify-center w-96 overflow-y-auto">
            <div className="flex items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform border overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95">
                <div className="mt-2">
                  <textarea
                    id="about"
                    name="about"
                    rows={3}
                    onChange={(e) => setByline(e.target.value)}
                    value={byline}
                    className="block w-full rounded-md border-0 pl-1 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    defaultValue={""}
                  />
                </div>
                {/*  */}
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    onClick={(e) => updateByLine(e)}
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                  >
                    Update Byline
                  </button>
                  <button
                    type="button"
                    data-autofocus
                    onClick={() => setOpenByline(false)}
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </>
    );
  };

  const updateImageModal = () => {
    return (
      <>
        <Dialog
          open={openImage}
          onClose={setOpenImage}
          className="relative z-10"
        >
          <div className="bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in" />
          <div className="fixed inset-0 z-10 items-center justify-center  overflow-y-auto">
            <div className="flex items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform border overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95">
                <UploadImageInit />
                {/*  */}
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    data-autofocus
                    onClick={() => setOpenImage(false)}
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  >
                    close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </>
    );
  };

  return (
    <>
      <div className="grid grid-cols-4 pt-4">
        <div className="col-span-1"></div>
        <div className="col-span-2">
          <div className="border border-gray-300 rounded-lg p-6 max-w-full shadow-lg bg-white">
            <img
              src={journalist?.image.imageURL}
              alt="Profile"
              className="w-48 h-48 rounded-xl object-cover mx-auto mb-4"
            />
            <h2 className="text-xl font-bold text-center text-gray-900">
              {journalist?.firstName} {journalist?.lastName}
            </h2>
            <p className="text-sm text-center text-gray-600 mb-4">
              {journalist?.email}
            </p>
            <p className="text-sm text-center text-gray-800 italic mb-6">
              {journalist?.byline}
            </p>

            <div className="flex justify-center space-x-4">
              <button
                className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-600 focus-visible:ring-offset-2"
                onClick={handleBylineChange}
              >
                Update Byline
              </button>
              <button
                className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-600 focus-visible:ring-offset-2"
                onClick={handleProfileImageChange}
              >
                Update Image
              </button>
            </div>
          </div>
        </div>
      </div>
      {bylineModal()}
      {updateImageModal()}
    </>
  );
};

export default profile;
