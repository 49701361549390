import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IJournalistModel } from "../../models/content-models/journalistModels";
import { IImageModel } from "../../models/content-models/imageModel";

interface JournalistState{
    journalist: IJournalistModel
}

const localJournalist = JSON.parse(localStorage.getItem('journalist') || '{}') as IJournalistModel

const initialState: JournalistState = {
    journalist: {
        cognitoID: "",
        journalistID: "",
        email: "",
        firstName: "",
        lastName: "",
        middle: "",
        expertiseCategories: [],
        sources: [],
        roles: [],
        byline: "",
        image: {} as IImageModel,
        phoneNumber: "",
    }
}

const journalistSlice = createSlice({
    name: "journalist",
    initialState,
    reducers: {
        updateJournalist: (state, action: PayloadAction<any>) =>{
            state.journalist = action.payload
            localStorage.setItem('journalist', JSON.stringify(state.journalist))
        }
    }
})

export const { updateJournalist } = journalistSlice.actions;

export default journalistSlice.reducer;