import { useState } from "react";
import { IImageModel } from "../../../models/content-models/imageModel";
import { IJournalistModel } from "../../../models/content-models/journalistModels";
import IFile from "../../../models/request-models/IFile";
import { S3BucketService } from "../../../services/s3BucketService";
import { ImageService } from "../../../services/imageService";
import { IMetaData } from "../../../models/content-models/articleModels";
import { IBaseResult } from "../../../models/baseResults";
import AlertError from "../shared/alert-error";
import { PhotoIcon } from "@heroicons/react/24/outline";
import { JournalistService } from "../../../services/journalistService";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

type Props = {
  location: string
}

const UploadImageInit = () => {
  const [image, setImage] = useState<IImageModel>();

  const imageService = new ImageService();
  const journalistService = new JournalistService();

  const [currentImage, setCurrentImage] = useState<any>({});
  const [previewImage, setPreviewImage] = useState<string>("");
  const [progress, setProgress] = useState<number>(0);
  const [presignedUrl, setPresignedUrl] = useState("");
  const [uploaded, setUploaded] = useState(false);
  const [message, setMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const [imageInfos, setImageInfos] = useState<Array<IFile>>([]);

  // react redux process
  const journalist = useSelector(
    (state: RootState) => state.journalist.journalist
  );
  const dispatch = useDispatch();

  console.log("journalist state", journalist);

  const selectImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files as FileList;
    setCurrentImage(selectedFiles?.[0]);
    const urlFile = URL.createObjectURL(selectedFiles?.[0]);
    setPreviewImage(urlFile);
    setProgress(0);
  };

  const continueSetup = async (e: any) => {
    e.preventDefault();
  };

  const upload = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    if (!currentImage) return;

    try {
      const s3BucketService = new S3BucketService();
      await s3BucketService
        .putNewImage("journalist-images", currentImage)
        .then(async (response) => {
          const tempImg = {
            imageID: "",
            imageName: response,
            imageURL: "",
            uploadedBy: "",
            imageCaption: "",
            metaData: [] as IMetaData[],
            submissionDate: 0,
          } as IImageModel;

          await imageService
            .PostImage(tempImg)
            .then(async (response: IBaseResult<IImageModel>) => {
              if (response.isSuccessful) {
                console.log("image response: ", response.result);
                setImage(response.result);
                
                let journalistUpdate = {
                  cognitoID: journalist.cognitoID,
                  journalistID: journalist.journalistID,
                  email: journalist.email,
                  firstName: journalist.firstName,
                  lastName: journalist.lastName,
                  middle: journalist.middle,
                  expertiseCategories: journalist.expertiseCategories,
                  sources: journalist.sources,
                  roles: journalist.roles,
                  byline: journalist.byline,
                  image: response.result,
                  phoneNumber: journalist.phoneNumber,
                };

                journalistUpdate!.image = response.result;

                await journalistService
                  .putJournalist(journalistUpdate, journalist?.journalistID)
                  .then((response: IBaseResult<boolean>) => {
                    setMessage("Image Uploaded");
                    setUploaded(true);
                  });
              } else {
                setMessage(response.errorMessage);
              }
            });
        })
        .catch((err) => {
          console.error("Error uploading file:", err);
          setError("Failed to upload file to S3 Bucket");
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      setError("Failed to upload file to S3 Bucket");
    }
  };

  const remove = (e: any) => {
    e.preventDefault();
    setCurrentImage(null);
    setPreviewImage("");
    localStorage.removeItem("presigned-url");
  };

  return (
    <>
      <div className=" p-10">
        <div className="col-span-1"></div>
        <div className="col-span-3">
          <label
            htmlFor="cover-photo"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Cover photo
          </label>
          <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
            <div className="text-center">
              {previewImage ? (
                <div className="h-96 w-96">
                  <img className="preview mt-2" src={previewImage} alt="" />
                </div>
              ) : (
                <>
                  <PhotoIcon
                    className="mx-auto h-12 w-12 text-gray-300"
                    aria-hidden="true"
                  />
                  <div className="mt-4 flex text-sm leading-6 text-gray-600">
                    <label className="btn btn-default p-0">
                      <input
                        type="file"
                        accept="image/*"
                        onChange={selectImage}
                      />
                    </label>
                    <p className="pl-1">or drag and drop</p>
                  </div>
                  <p className="text-xs leading-5 text-gray-600">
                    PNG, JPG, GIF up to 10MB
                  </p>
                </>
              )}
            </div>
          </div>
          <div>
            {error !== "" ? <>{<AlertError errorMessage={error} />}</> : <></>}

            {!uploaded ? (
              <>
                <div className="flex justify-end">
                  <button
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm m-2 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    disabled={!currentImage}
                    onClick={(e) => upload(e)}
                  >
                    Upload Image
                  </button>
                  <button
                    className="rounded-md bg-transparent border border-red-500 px-3 py-2 text-sm m-2 font-semibold text-red-500 shadow-sm hover:bg-red-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                    disabled={!currentImage}
                    onClick={(e) => remove(e)}
                  >
                    Remove Image
                  </button>
                </div>
              </>
            ) : (
              <>
                <button
                  className="rounded-md bg-indigo-600 px-3 py-2 text-sm m-2 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  disabled={!currentImage}
                  onClick={(e) => continueSetup(e)}
                >
                  Next
                </button>
              </>
            )}

            {message && (
              <div className="alert alert-secondary mt-3" role="alert">
                {message}
              </div>
            )}

            {imageInfos.length > 0 && (
              <div className="card mt-3">
                <div className="card-header">List of Images</div>
                <ul className="list-group list-group-flush">
                  {imageInfos.map((img, index) => (
                    <li className="list-group-item" key={index}>
                      <p>
                        <a href={img.url}>{img.name}</a>
                      </p>
                      <img src={img.url} alt={img.name} height="80px" />
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadImageInit;
