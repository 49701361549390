import { IBaseResult } from "../models/baseResults"
import { IImageModel } from "../models/content-models/imageModel"
import BaseService from "./baseService"

export class ImageService extends BaseService{
    async GetAllImages(query: string): Promise<IBaseResult<IImageModel>>{
        return BaseService.GetData(`${process.env.REACT_APP_ARTICLES_API_URL}/api/images?${query}`)
    }

    async GetImage(key: string, value: string, query: string): Promise<IBaseResult<IImageModel>>{
        return BaseService.GetData(`${process.env.REACT_APP_ARTICLES_API_URL}/api/images/${key}/${value}?${query}`)
    }

    async GetImageList(key: string, value: string, query: string): Promise<IBaseResult<IImageModel>>{
        return BaseService.GetData(`${process.env.REACT_APP_ARTICLES_API_URL}/api/images/${key}/${value}/list?${query}`)
    }

    async PostImage(image: IImageModel): Promise<IBaseResult<IImageModel>>{
        return BaseService.PostData(`${process.env.REACT_APP_ARTICLES_API_URL}/api/images`, image)
    }

    async RemoveImage(imageID: string): Promise<IBaseResult<boolean>>{
        return BaseService.DeleteData(`${process.env.REACT_APP_ARTICLES_API_URL}/api/images/${imageID}`)
    }
}