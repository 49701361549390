/* eslint-disable no-undef */
/* eslint-disable react-hooks/rules-of-hooks */
import { Editor } from "@tinymce/tinymce-react";
import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../redux/store";
import { IArticleModel } from "../../../models/content-models/articleModels";
import { updateArticleSubmission } from "../../../redux/slice/submitArticleSlice";

const tinyMceEditor = ({ initialValue }: any) => {
  const editorRef = useRef({} as any);
  const [dirty, setDirty] = useState(false);

  const extractPlainTExtFromHTML = (html: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const articleSubmission = useSelector(
    (state: RootState) => state.articleSubmission?.article
  );
  const dispatch = useDispatch();

  const save = () => {
    console.log("editor ref", editorRef);
    if (editorRef.current) {
      const content = editorRef.current!.getContent();
      const plainText = extractPlainTExtFromHTML(content);
      console.log(plainText);
      setDirty(false);
      editorRef.current!.setDirty(false);

      let savedArticle = {
        articleTitle: articleSubmission.articleTitle,
        articleID: "",
        articleType: articleSubmission.articleType,
        categories: articleSubmission.categories,
        subCategory: articleSubmission.subCategory,
        articleLead: articleSubmission.articleLead,
        content: content,
        plainText: plainText,
        image: articleSubmission.image,
        journalist: articleSubmission.journalist,
        premiumContent: articleSubmission.premiumContent,
        sponsoredContent: articleSubmission.sponsoredContent,
        submissionDate: 0,
        metaData: articleSubmission.metaData,
        publisher: articleSubmission.publisher,
      } as IArticleModel;

      console.log("saved content", savedArticle);

      dispatch(updateArticleSubmission(savedArticle));
    }
  };

  useEffect(() => {
    // Auto-save if dirty changes to true or when component is unmounted
    if (dirty) {
      const intervalId = setInterval(() => {
        save();
      }, 1000); // Autosave every 10 seconds

      return () => clearInterval(intervalId); // Clear interval on unmount
    }
  }, [dirty]);

  return (
    <>
      <div className="flex p-2">
        {!dirty ? (
          <p>Latest changes saved!</p>
        ) : (
          <>
            <button
              onClick={save}
              disabled={!dirty}
              className="rounded-md bg-indigo-600 p-1 ml-4 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Save
            </button>
          </>
        )}
      </div>
      <Editor
        apiKey="j2snsiv88yr49ik2oqqdrbou5ec67spbtyuggj9jmn3a7yay"
        onInit={(evt, editor) => (editorRef.current = editor)}
        onDirty={() => setDirty(true)}
        init={{
          plugins:
            "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker  ",
          toolbar:
            "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
          tinycomments_mode: "embedded",
          tinycomments_author: "Author name",
          mergetags_list: [
            { value: "First.Name", title: "First Name" },
            { value: "Email", title: "Email" },
          ],
          ai_request: (request: any, respondWith: any) =>
            respondWith.string(() =>
              Promise.reject("See docs to implement AI Assistant")
            ),
        }}
      />
    </>
  );
};

export default tinyMceEditor;
