import { Dialog } from "@headlessui/react"
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline"
import { useCallback, useEffect, useState } from "react"
import { SelectedTopicService } from "../../../services/selectedTopicService"
import { ISelectedTopic, IUserTopic } from "../../../models/content-models/selectedTopicModels"
import useSession from "../../../hooks/useSession"
import { IBaseResult } from "../../../models/baseResults"
import { format } from "date-fns"

/* eslint-disable jsx-a11y/anchor-is-valid */
function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

const SelectedTopicsTable = () => {

    const selectedTopicService = new SelectedTopicService()
    const [loading, setLoading] = useState(true)
    const [message, setMessage] = useState('')
    const [open, setOpen] = useState(false)
    const { getToken } = useSession()
    const [userTopicRecord, setUserTopicRecord] = useState<IUserTopic>()

    // get topics

    const getTopics = useCallback(async () => {
        const token = await getToken()

        await selectedTopicService.getTopic(token.JournalistID).then((response: IBaseResult<IUserTopic>) => {
            if (response.isSuccessful) {
                setUserTopicRecord(response.result)
            }
        }).catch(err => {

        }).finally(() => setLoading(false))
    }, [])

    // update topics

    const addTopic = (e: any) => {
        e.preventDefault()
    }

    const updateTopics = (e: any) => {
        e.preventDefault()
    }

    // mark topic as covered
    const markTopicCovered = (e: any) => {
        e.preventDefault()
    }

    // remove topic from list

    const removeTopic = async (e: any, item: ISelectedTopic) => {
        e.preventDefault()
        setLoading(true)
        const token = await getToken()
        await selectedTopicService.removeTopic(token.JournalistID, item.topicID).then((response: IBaseResult<IUserTopic>) => {
            if (response.isSuccessful) {
                setUserTopicRecord(response.result)
            }
        }).catch(err => {

        }).finally(() => setLoading(false))
    }

    const clearTopicsPrompt = (e: any) => {
        e.preventDefault();
        setOpen(true)
    }

    const clearTopics = async (e: any) => {
        e.preventDefault()
        setLoading(true)
        const token = await getToken()
        await selectedTopicService.clearTopics(token.JournalistID).then((response: IBaseResult<IUserTopic>) => {
            if (response.isSuccessful) {
                setUserTopicRecord(response.result)
            }
        }).catch(err => {

        }).finally(() => {
            setLoading(false)
            setOpen(false)
        })
    }

    useEffect(() => {
        getTopics()
    }, [])

    const clearTopicsModal = () => {
        return <>
            <Dialog open={open} onClose={setOpen} className="relative z-10">
                <div

                    className="bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />

                <div className="fixed inset-0 z-10 items-center justify-center w-96 overflow-y-auto">
                    <div className="flex items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <div

                            className="relative transform border overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                        >
                            <div className="sm:flex sm:items-start">
                                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                    <ExclamationTriangleIcon aria-hidden="true" className="h-6 w-6 text-red-600" />
                                </div>
                                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                    <h3 className="text-base font-semibold leading-6 text-gray-900">
                                        Clear Topics
                                    </h3>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            Are you sure you want to clear your topics?
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                <button
                                    type="button"
                                    onClick={(e) => clearTopics(e)}
                                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                >
                                    Clear topics
                                </button>
                                <button
                                    type="button"
                                    data-autofocus
                                    onClick={() => setOpen(false)}
                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    }

    return <>
        <div className="sm:px-2 lg:px-2 pt-2">
            <div className="sticky top-0 grid grid-rows-1 grid-cols-5 gap-2">
                {/* <button
                    type="button"
                    onClick={(e) => addTopic(e)}
                    className="items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-gray-200 ring-1 ring-inset ring-gray-300 hover:bg-green-700 focus:z-10"
                >
                    Add Topic
                </button> */}
                <button
                    type="button"
                    onClick={(e) => clearTopicsPrompt(e)}
                    className="items-center rounded-md bg-transparent px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-red-600 hover:text-gray-200 focus:z-10"
                >
                    Clear Topics
                </button>
            </div>
            <div className="mt-1 flow-root">
                <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle">
                        <table className="min-w-full border-separate border-spacing-0">
                            <thead>
                                <tr>
                                    <th
                                        scope="col"
                                        className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                                    >

                                        Title
                                    </th>
                                    <th
                                        scope="col"
                                        className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                                    >

                                        Link
                                    </th>
                                    <th
                                        scope="col"
                                        className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                                    >
                                        Date Added
                                    </th>
                                    <th
                                        scope="col"
                                        className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                                    >
                                        <span className="sr-only">Remove</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {userTopicRecord?.selectedTopics.map((item, itemIdx) => (
                                    <tr key={item.topicID} className="hover:bg-gray-100">
                                        <td
                                            className={classNames(
                                                itemIdx !== userTopicRecord.selectedTopics.length - 1 ? 'border-b border-gray-200' : '',
                                                'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8',
                                            )}
                                        >
                                            {item.topicTitle}
                                        </td>
                                        <td
                                            className={classNames(
                                                itemIdx !== userTopicRecord.selectedTopics.length - 1 ? 'border-b border-gray-200' : '',
                                                'hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell',
                                            )}
                                        >
                                            <a href={`${item.topicURL}`} target="_blank" className="text-indigo-600 hover:text-indigo-900">
                                                Link<span className="sr-only">, {item.topicURL}</span>
                                            </a>
                                        </td>
                                        <td
                                            className={classNames(
                                                itemIdx !== userTopicRecord.selectedTopics.length - 1 ? 'border-b border-gray-200' : '',
                                                'hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell',
                                            )}
                                        >
                                            {`${format(
                                                item.dateAdded || new Date(),
                                                "EEEE, MMMM dd, yyyy"
                                            )}`}
                                        </td>
                                        <td
                                            className={classNames(
                                                itemIdx !== userTopicRecord.selectedTopics.length - 1 ? 'border-b border-gray-200' : '',
                                                'relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-8 lg:pr-8',
                                            )}
                                        >
                                            <button onClick={(e) => removeTopic(e, item)} className="text-red-600 hover:text-red-900">
                                                Remove
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        {clearTopicsModal()}
    </>
}

export default SelectedTopicsTable;