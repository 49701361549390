import { IJournalistModel } from "../../../models/content-models/journalistModels"

const journalistCard = ({journalist}: any) => {
    return <>
        <li
            key={journalist.email}
            className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow"
        >
            <div className="flex flex-1 flex-col p-8">
                <img className="mx-auto h-32 w-32 flex-shrink-0 rounded-full" src={journalist.image.imageURL} alt="" />
                <h3 className="mt-6 text-sm font-medium text-gray-900">{journalist.firstName} {journalist.lastName}</h3>
                <dl className="mt-1 flex flex-grow flex-col justify-between">
                    <dt className="sr-only">Expertise</dt>
                    <dd className="text-sm text-gray-500">{journalist.expertiseCategories[0]}</dd>
                    <dt className="sr-only">Byline</dt>

                </dl>
            </div>
            <div>
                <div className="-mt-px flex divide-x divide-gray-200">
                    <dd className="">
                        <span className="inline-flex items-center px-2 py-1 text-sm font-normal text-gray-700 ring-1 ring-inset ring-green-600/20">
                            {journalist.byline}
                        </span>
                    </dd>
                </div>
            </div>
        </li>
    </>
}

export default journalistCard;