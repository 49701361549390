import { useState } from "react";
import { JournalistService } from "../../../services/journalistService";
import { IJournalistInvite } from "../../../models/content-models/journalistModels";
import { BeatLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { CognitoService } from "../../../services/cognitoService";
import { AuthUser } from "aws-amplify/auth";

const InviteJournalist = () =>{

    const cognito = new CognitoService();
    const journalistService =  new JournalistService();
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [invited, setInvited] = useState(false);
    const navigate = useNavigate()

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        const photoKeyStr = localStorage.getItem('presigned-key');

        var user = await cognito.getUser().then(async (user: AuthUser) => {
            console.log('cognito user: ', user)
            return user
        })

        const invite = {
            iD: '',
            email: email,
            inviteeEmail: user.signInDetails!.loginId!,
            roles: ['journalist'],
            created: 0
        } as IJournalistInvite

        await journalistService.PostJournalistInvite(invite).then(response =>{
            if(response.isSuccessful){
                setMessage(`${email} invited`);
                setInvited(true);
                navigate('/journalists')
            }
            else{
                setMessage(`failed to invite ${email}`);
            }

        }).catch(err =>{
            setMessage('failed to submit journalist');
        }).finally(() =>{
            setLoading(false);
        })
    }

    const cancel = (e: any) =>{
        e.preventDefault()
        navigate('/journalists')
    }

    return <>
     <main className="m-4">
            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
                <form>
                    <div className="space-y-12">
                        <div className="border-b border-gray-900/10 pb-12">
                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 ">
                                <div className="grid grid-cols-3">
                                    {/* first name */}
                                    <div className="col-span-1 mr-4">
                                        <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                                            Email
                                        </label>
                                        <div className="mt-2">
                                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                <input
                                                    type="email"
                                                    name="email"
                                                    id="email"
                                                    autoComplete="title"
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    value={email}
                                                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                    placeholder=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-6 flex items-center justify-end gap-x-6">
                            <button type="button" onClick={e => cancel(e)} className="text-sm font-semibold leading-6 text-gray-900">
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                onClick={e => handleSubmit(e)}
                                disabled={loading}
                            >
                                {loading ? (
                                    <>
                                        <BeatLoader
                                            color="#FFFFFF"
                                            loading={loading}
                                            size={18}
                                            aria-label="Loading Spinner"
                                            data-testid="loader"
                                        />
                                    </>
                                ) : (
                                    <>Invite Journalist</>
                                )}
                            </button>
                        </div>
                </form>
            </div>
        </main>
    </>
}

export default InviteJournalist