import { Fragment, useCallback, useEffect, useState } from "react";
import { ArticleService } from "../../../../services/articleSerivce";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronUpDownIcon, CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { RSSFeedService } from "../../../../services/rssFeedService";
import { IBaseResult, IPagedResults } from "../../../../models/baseResults";
import { IRSSItem } from "../../../../models/rss-models/RSSItem";
import LoadingIndicator from "../loading";
import { IFeedSource } from "../../../../models/rss-models/FeedSource";
import useSession from "../../../../hooks/useSession";
import { SelectedTopicService } from "../../../../services/selectedTopicService";
import { ISelectedTopic } from "../../../../models/content-models/selectedTopicModels";

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

const categories = [
    {
        name: "Headlines",
        value: "Headlines"
    },
    {
        name: "Business",
        value: "Business"
    },
    {
        name: "Finance",
        value: "Finance"
    },
    {
        name: "Politics",
        value: "Politics"
    },
    {
        name: "Company",
        value: "Company"
    },
    {
        name: "US News",
        value: "US News"
    },
]

const DynamicFeedTable = () => {

    const rssfeedService = new RSSFeedService();
    const selectedTopicService = new SelectedTopicService()
    const [selectedCategory, setSelectedCategory] = useState(categories[0]);
    const [sortedFeed, setSortedFeed] = useState<IRSSItem[]>([])
    const [selectedOutlet, setSelectedOutlet] = useState('')
    const [items, setItems] = useState(250)
    const [display, setDisplay] = useState(false)
    const [page, setPage] = useState(1)
    const { getToken } = useSession()
    const [loading, setLoading] = useState(true)
    const [pagedResults, setPagedResults] = useState<IPagedResults<IRSSItem>>({} as IPagedResults<IRSSItem>)
    const [message, setMessage] = useState('')

    const sortFeed = (unsortedFeed: IRSSItem[]): IRSSItem[] => {
        return unsortedFeed.sort((a, b) => {
            // Parse the date strings into Date objects
            const dateA = new Date(a.pubDate);
            const dateB = new Date(b.pubDate);

            // Compare the dates
            return dateB.getTime() - dateA.getTime();
        });
    }

    const createSelectedFeedItem = async (e: any, item: IRSSItem) => {
        e.preventDefault()
        console.log('selected feed item', item)
        const token = await getToken()

        const topic = {
            topicID: '',
            topicTitle: item.title,
            topicURL: item.link,
            topicPoints: [],
            dateAdded: 0,
            active: true
        } as ISelectedTopic

        await selectedTopicService.postTopic(topic, token.JournalistID).then((response: IBaseResult<any>) => {
            if (response.isSuccessful) {
                setDisplay(true)
            } else {
                // idk do something
            }
        }).catch((err) => {

        })
    }

    const getFeed = async () => {
        setLoading(true)
        console.log('category', selectedCategory)
        const queryString = `key=Category&value=${selectedCategory.name}&items=${items}&page=${page}`
        await rssfeedService.getRSSFeed(queryString).then((response: IBaseResult<IRSSItem>) => {
            console.log('rss feed: ', response)
            if (response.isSuccessful) {
                const sortedItems = sortFeed(response.results.pagedItems)
                setPagedResults(response.results)
                setSortedFeed([])
                setSortedFeed(sortedItems)
            }
            else {
                throw new Error(response.errorMessage)
            }
        }).catch((err) => {
            console.log(err)
            setMessage(err.message)
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        getFeed()
    }, [])

    useEffect(() => {
        const intervalId = setInterval(async () => {
            setDisplay(false)
        }, 5000);

        return () => clearInterval(intervalId);
    }, [display])

    const addedTopicIndicator = () => {
        return (
            <>{
                display ? <>
                    <div className="pointer-events-none flex fixed inset-x-0 bottom-0 px-6 pb-6">
                        <div className="pointer-events-auto flex ml-auto max-w-xl rounded-xl bg-green-200 p-6 shadow-lg ring-1 ring-gray-900/10">
                            <p className="text-sm leading-2 text-gray-900">
                                Added Topic To List
                            </p>
                            <div className=" flex-1 justify-end">
                                <button type="button" onClick={() => setDisplay(false)} className="-m-3 p-3 focus-visible:outline-offset-[-4px]">
                                    <span className="sr-only">Dismiss</span>
                                    <XMarkIcon aria-hidden="true" className="h-5 w-5 text-gray-900" />
                                </button>
                            </div>
                        </div>
                    </div>
                </> : <></>
            }
            </>

        )
    }

    const categorySelect = () => {
        return <>
            <div className="sm:col-span-2">
                <label htmlFor="country" className="block text-sm font-medium text-gray-900">
                    Categories
                </label>
                <div className="">
                    <Listbox value={selectedCategory} onChange={(e) => { setSelectedCategory(e); console.log('selected', e) }}>
                        {({ open }) => (
                            <>
                                <div className="relative mt-1">
                                    <Listbox.Button className="relative w-32 cursor-default rounded-md py-1.5 pl-3 pr-10 text-left shadow-sm ring-1 ring-inset ring-gray-700 focus:outline-none focus:ring-2 focus:ring-green-600 sm:text-sm sm:leading-6">
                                        <span className="block truncate">{selectedCategory.name}</span>
                                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </span>
                                    </Listbox.Button>
                                    <Transition
                                        show={open}
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <Listbox.Options className="absolute mt-1 bg-white max-h-60 w-56 overflow-auto rounded-md py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                            {categories.map((category) => (
                                                <Listbox.Option
                                                    key={category.value}
                                                    className={({ active }) =>
                                                        classNames(
                                                            active ? 'bg-green-600' : 'text-gray-800',
                                                            'relative cursor-default select-none py-2 pl-3 pr-9'
                                                        )
                                                    }
                                                    value={category}
                                                >
                                                    {({ selected, active }) => (
                                                        <>
                                                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                                {category.name}
                                                            </span>

                                                            {selected ? (
                                                                <span
                                                                    className={classNames(
                                                                        active ? 'text-white' : 'text-green-600',
                                                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                    )}
                                                                >
                                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                </span>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </Transition>
                                </div>
                            </>
                        )}
                    </Listbox>
                </div>
            </div></>
    }


    return <>
        <div className="sm:px-2 lg:px-2">
            <div className="sticky top-0 grid grid-rows-1 grid-cols-5 gap-2">

            </div>
            <div className="mt-1 flow-root">
                <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle">
                        {!loading ? <>
                            <table className="min-w-full border-separate border-spacing-0">
                                <thead>
                                    <tr>
                                        <th
                                            scope="col"
                                            className="sticky max-w-48 top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                                        >
                                            <div className="col-span-1">
                                                {categorySelect()}
                                            </div>
                                            Outlet
                                        </th>
                                        <th
                                            scope="col"
                                            className="sticky max-w-8  z-10 top-0 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                                        >
                                            <div className="col-span-">
                                                <button
                                                    className="text-nowrap flex w-16 justify-center rounded-md  bg-green-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                                                    onClick={() => getFeed()}
                                                >
                                                    Get Feed
                                                </button>
                                            </div>
                                        </th>
                                        <th
                                            scope="col"
                                            className="sticky max-w-16 z-10 top-0 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                                        >
                                            <div className="col-span-1">

                                            </div>
                                            Title
                                        </th>
                                        <th
                                            scope="col"
                                            className="sticky top-0  z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                                        >
                                            <div className="col-span-1">

                                            </div>
                                            Link
                                        </th>
                                        <th
                                            scope="col"
                                            className="sticky top-0  z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                                        >
                                            <div className="col-span-1">

                                            </div>
                                            Published
                                        </th>
                                        <th
                                            scope="col"
                                            className="sticky top-0  z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                                        >
                                            <div className="col-span-1">

                                            </div>
                                            Description
                                        </th>


                                    </tr>
                                </thead>
                                <tbody>
                                    {sortedFeed.map((item, itemIdx) => (
                                        <tr className="hover:bg-gray-100">
                                            <td
                                                className={classNames(
                                                    itemIdx !== pagedResults.totalItems - 1 ? 'border-b border-gray-200' : '',
                                                    'whitespace-nowrap py-4 max-w-32 columns-xs pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8',
                                                )}
                                            >
                                                {item.outlet}
                                            </td>
                                            <td
                                                className={classNames(
                                                    itemIdx !== pagedResults.totalItems - 1 ? 'border-b border-gray-200' : '',
                                                    'whitespace-nowrap w-32 columns-xs text-sm font-medium text-gray-900',
                                                )}>
                                                <button
                                                    className="flex w-24 justify-center rounded-md  bg-green-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                                                    onClick={e => createSelectedFeedItem(e, item)}>
                                                    Select
                                                </button>
                                            </td>
                                            <td
                                                className={classNames(
                                                    itemIdx !== pagedResults.totalItems - 1 ? 'border-b border-gray-200' : '',
                                                    'hidden whitespace-nowrap max-w-96 overflow-x-scroll px-3 py-4 text-sm text-gray-500 sm:table-cell',
                                                )}
                                            >
                                                {item.title}
                                            </td>
                                            <td
                                                className={classNames(
                                                    itemIdx !== pagedResults.totalItems - 1 ? 'border-b border-gray-200' : '',
                                                    'relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-8 lg:pr-8',
                                                )}
                                            >
                                                <a href={`${item.link}`} target="_blank" className="text-indigo-600 hover:text-indigo-900">
                                                    Link<span className="sr-only">, {item.link}</span>
                                                </a>
                                            </td>
                                            <td
                                                className={classNames(
                                                    itemIdx !== pagedResults.totalItems - 1 ? 'border-b border-gray-200' : '',
                                                    'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
                                                )}
                                            >
                                                {item.pubDate}
                                            </td>
                                            <td
                                                className={classNames(
                                                    itemIdx !== pagedResults.totalItems - 1 ? 'border-b border-gray-200' : '',
                                                    'hidden whitespace-nowrap max-w-96 overflow-x-scroll  px-3 py-4 text-sm text-gray-500 lg:table-cell',
                                                )}
                                            >
                                                {item.description}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </> : <> <LoadingIndicator /></>}
                    </div>
                </div>
            </div>
            {addedTopicIndicator()}
        </div>
    </>
}

export default DynamicFeedTable;