import { configureStore } from "@reduxjs/toolkit";
import loadingReducer from './slice/globalLoadingSlice';
import routeReducer from './slice/routeSlice';
import journalistReducer from "./slice/journalistSlice"
import articleSubmissionReducer from "./slice/submitArticleSlice"
import imageReducer from "./slice/uploadedImage"

export const store = configureStore({
    reducer: {
        loading: loadingReducer,
        journalist: journalistReducer,
        articleSubmission: articleSubmissionReducer,
        image: imageReducer,
        route: routeReducer
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type  AppDispatch = typeof store.dispatch;