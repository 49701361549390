import { BrowserRouter, Route, Routes } from "react-router-dom";
import PageNotFound from "./app/components/shared/page-not-found";
import Verify from "./app/auth/verify";
import SignIn from "./app/auth/sign-in";
import UserProtectedRoute from "./context/userProtectedRoute";
import ArticleQueue from "./app/components/article-queue/article-queue";
import ContentManager from "./app/components/content-manager/content";
import Dashboard from "./app/components/dashboard/dashboard";
import Journalists from "./app/components/journalists/journalists";
import Rankings from "./app/components/rankings/rankings";
import SubmitArticle from "./app/components/submit-article/submitArticle";
import DynamicFeedTable from "./app/components/shared/tables/dynamic-feed-table";
import SelectedTopicsTable from "./app/components/selected-topics/selected-topics-table";
import AddFeedSource from "./app/components/content-manager/feed-sources/AddFeedSource";
import PressReleaseTable from "./app/components/shared/tables/press-release-table";
import Signup from "./app/auth/signup";
import AdminProtectedRoute from "./context/adminProtectedRoute";
import InviteJournalist from "./app/components/journalists/invite-journalist";
import RouteWatcher from "./hooks/routeWatcher";
import Profile from "./app/components/accounts/profile/profile";
import UploadImageInit from "./app/components/setup/upload-image";
import AddByline from "./app/components/setup/add-byline";
import MyArticles from "./app/components/my-articles/myarticles";
import ArticlePreview from "./app/components/submit-article/article-preview";

const adminRoutes = () => {
  return (
    <>
      <Route
        path="/"
        element={
          <UserProtectedRoute>
            <Dashboard />
          </UserProtectedRoute>
        }
      />
      <Route
        path="/rankings"
        element={
          <AdminProtectedRoute>
            <Rankings />
          </AdminProtectedRoute>
        }
      />
      <Route
        path="/submit-article"
        element={
          <UserProtectedRoute>
            <SubmitArticle />
          </UserProtectedRoute>
        }
      />
      <Route
        path="/queue/:articleId"
        element={
          <UserProtectedRoute>
            <ArticleQueue />
          </UserProtectedRoute>
        }
      />
      <Route
        path="/content-manager"
        element={
          <AdminProtectedRoute>
            <ContentManager />
          </AdminProtectedRoute>
        }
      />
      <Route
        path="/journalists"
        element={
          <AdminProtectedRoute>
            <Journalists />
          </AdminProtectedRoute>
        }
      />
      <Route
        path="/journalists/invite"
        element={
          <AdminProtectedRoute>
            <InviteJournalist />
          </AdminProtectedRoute>
        }
      />
      <Route
        path="/top-leads"
        element={
          <UserProtectedRoute>
            <DynamicFeedTable />
          </UserProtectedRoute>
        }
      />
      <Route
        path="/press-releases"
        element={
          <UserProtectedRoute>
            <PressReleaseTable />
          </UserProtectedRoute>
        }
      />
      <Route
        path="/add-source"
        element={
          <UserProtectedRoute>
            <AddFeedSource />
          </UserProtectedRoute>
        }
      />
      <Route
        path="/selected-leads"
        element={
          <UserProtectedRoute>
            <SelectedTopicsTable />
          </UserProtectedRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <UserProtectedRoute>
            <Profile />
          </UserProtectedRoute>
        }
      />
      <Route
        path="/account-setup/image"
        element={
          <UserProtectedRoute>
            <UploadImageInit />
          </UserProtectedRoute>
        }
      />
      <Route
        path="/account-setup/byline"
        element={
          <UserProtectedRoute>
            <AddByline />
          </UserProtectedRoute>
        }
      />
            <Route
        path="/my-articles"
        element={
          <UserProtectedRoute>
            <MyArticles />
          </UserProtectedRoute>
        }
      />
    </>
  );
};

const journalistRoutes = () => {
  return <></>;
};

const baseRoutes = () => {
  return (
    <>
      <Route path="/verify/:inviteID" element={<Verify />} />
      <Route path="*" element={<PageNotFound />} />
      <Route path="/sign-in" element={<SignIn />} />
      <Route path="/sign-up/:inviteID" element={<Signup />} />
    </>
  );
};

const routeHandler = () => {
  return (
    <>
      <BrowserRouter>
        <RouteWatcher />
        <Routes>
          {adminRoutes()}
          {journalistRoutes()}
          {baseRoutes()}
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default routeHandler;
