
// import { IBasicUserInfoModel } from "../models/auth-models/userModel";
import { Amplify } from "aws-amplify";
import { signUp, signIn, signOut, deleteUser, confirmSignUp, getCurrentUser, resendSignUpCode, resetPassword, confirmResetPassword, updateUserAttributes, SignUpInput, ConfirmSignUpInput, SignInInput, ResendSignUpCodeInput, ResendSignUpCodeOutput, AuthUser, UpdateUserAttributeInput, ConfirmResetPasswordInput, ResetPasswordInput, ConfirmSignUpOutput, SignUpOutput, SignInOutput, ResetPasswordOutput } from "aws-amplify/auth";
import { BehaviorSubject } from "rxjs";

export class CognitoService {
    private authenticationSubject: BehaviorSubject<any> | undefined;

    public signUp(signupInput: SignUpInput): Promise<SignUpOutput> {
      this.configureAmplify();
        return signUp(signupInput).then((response) =>{
          return response;
        }).catch((err: any) =>{
          throw err;
        })
    }

    public confirmSignUp(user: ConfirmSignUpInput): Promise<ConfirmSignUpOutput>{
      this.configureAmplify();
        return confirmSignUp(user);
      }
    
      public async signIn(user: SignInInput): Promise<any>{
        this.configureAmplify();
        await signIn(user).then((response) =>{
          if(response.nextStep.signInStep === "CONFIRM_SIGN_UP"){
            throw {type: 'UserNotConfirmedException'};
          }
        }).catch(err =>{
          throw err;
        })
      }
    
      public deleteUser(): Promise<any>{
        this.configureAmplify();
        return deleteUser();
      }
    
      public async signOut(): Promise<any>{
        this.configureAmplify();
        return signOut().then(() =>{
          this.authenticationSubject?.next(false);
          window.localStorage.clear();

        });
      }

      public resetPassword(username : string): Promise<ResetPasswordOutput>{
        this.configureAmplify();
        var input = {
          username: username
        } as ResetPasswordInput;
        return resetPassword(input);
      }

      public ConfirmResetPassword(username: string, code: string, newPassword: string): Promise<any>{
        this.configureAmplify();
        var input = {
          username: username,
          newPassword: newPassword,
          confirmationCode: code,
        } as ConfirmResetPasswordInput;
        return confirmResetPassword(input);
      }
    
      public getUser(): Promise<AuthUser>{
        this.configureAmplify();
        let user = getCurrentUser()
        return user;
      }
    
      public isAuthenticated(): boolean {
        return this.authenticationSubject?.value
      }

      public resendCode(email: string): Promise<ResendSignUpCodeOutput> {
        this.configureAmplify();
        const resendSignupInput = {
          username: email
        } as ResendSignUpCodeInput;
        return resendSignUpCode(resendSignupInput);
      }
    
      public promiseAuthenticated(): Promise<boolean>{
        this.configureAmplify();
        if (this.authenticationSubject?.value) {
          return Promise.resolve(true);
        } else {
          return this.getUser()
          .then((user: any) => {
            
            if (user) {
              return true;
            } else {
              return false;
            }
          }).catch(() => {
            return false;
          });
        }
      }
    
    //   public async updateEmail(newEmail: string): Promise<any>{
    //     const user = await getCurrentUser();
    //     const updateUserAttributes = {} as UpdateUserAttributeInput;
    //     return updateUserAttributes(updateUserAttributes).then((result) =>{
    //     })
    // }

    private configureAmplify(){
      Amplify.configure({
        Auth: {
          Cognito: {
            userPoolClientId: process.env.REACT_APP_USER_POOL_APP_CLIENT_ID || '',
            userPoolId: process.env.REACT_APP_USER_POOL_ID || '',
          }
        }
      });

      this.authenticationSubject = new BehaviorSubject<boolean>(false);
    }


}

