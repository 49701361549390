/* eslint-disable react-hooks/rules-of-hooks */
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CognitoService } from "../../services/cognitoService";
import { useCookies } from "react-cookie";
import useSession from "../../hooks/useSession";
import { ConfirmSignUpInput, SignInInput } from "aws-amplify/auth";
import { JournalistService } from "../../services/journalistService";

const verify = () => {
    const { generateToken, resend, confirmSignUp } = useSession()
    const [verify, setVerify] = useState("");
    const [userCooke, setUserCookie, removeUserCookie] = useCookies(['_new_user'])
    const [journalist, setJournalist] = useCookies(['_temp_journalist'])

    console.log('user cookie: ',  userCooke)

    const { inviteID } = useParams();
    var navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [verifyResend, setVerifyResend] = useState(false);
    const [message, setMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [messageSeverity, setMessageSeverity] = useState(2);

    const journalistService = new JournalistService();

    document.title = 'Verify';

    const [verifyCode, setVerifyCode] = useState("");

    const resendCode = async (e: any) => {
        e.preventDefault();
        console.log('resent code')

        await resend(journalist._temp_journalist?.email).then((response: boolean) => {
            if (response) {
                setMessage(`Sent new confirmation code to ${journalist._temp_journalist?.email}`)
                setVerifyResend(true);
            }
        })
    }


    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        setSubmitted(true);

        console.log('username: ', journalist._temp_journalist?.email)

        const confirmSignup = {
            username: journalist._temp_journalist?.cognitoID,
            confirmationCode: verifyCode
        } as ConfirmSignUpInput;

        const signIn = {
            username: journalist._temp_journalist?.email,
            password: userCooke._new_user?.pwd,
        } as SignInInput

        await confirmSignUp(confirmSignup, signIn).then(async response => {
            if (response) {
                navigate('/account-setup/image', {replace: true})
            }
        }).catch((err) => {
            console.log(err)
            setMessage("Verification Failed");
        }).finally(() => {
            setLoading(false)
            removeUserCookie('_new_user')
        })
    }

    useEffect(() => {
        document.title = 'Verify';
    });

    return <><main>
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
            <form className="container">
                <div className="space-y-12">
                    <div className="border-b border-gray-900/10 pb-12 m-4">
                        <h2 className=" text-4xl font-semibold leading-7 text-gray-900 dark:text-slate-200">
                            Verify
                        </h2>
                        <p className="mt-2 text-sm leading-6 text-gray-600 dark:text-slate-300">
                            Enter the code sent to {userCooke._new_user?.email} to complete verification
                        </p>

                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="first-name"
                                    className="block text-sm font-medium leading-6 text-gray-900 dark:text-slate-300"
                                >
                                    Verification Code
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        onChange={(e) => setVerifyCode(e.target.value)}
                                        value={verifyCode}
                                        autoComplete="given-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm pl-2 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <p className="text-red-600">{message}</p>
                </div>
                <div className="mt-6 m-4 flex items-center justify-start gap-x-6">
                    <button
                        type="submit"
                        className="rounded-md bg-stone-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-stone-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={resendCode}
                    >
                        Resend Code
                    </button>
                    <button
                        type="submit"
                        className="rounded-md bg-green-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={handleSubmit}
                    >
                        Verify
                    </button>
                </div>
            </form>
        </div>
    </main></>
}

export default verify;