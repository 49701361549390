import { IBaseResult } from "../models/baseResults";
import { IJournalistInvite, IJournalistModel } from "../models/content-models/journalistModels";
import BaseService from "./baseService";

export class JournalistService extends BaseService{
    async getAllJournalists(query: string): Promise<IBaseResult<IJournalistModel>>{
        return BaseService.GetData(`${process.env.REACT_APP_JOURNALISTS_API_URL}/api/journalists?${query}`)
    }

    async getJournalist(key: string, journalistId: string): Promise<IBaseResult<IJournalistModel>>{
        return BaseService.GetData(`${process.env.REACT_APP_JOURNALISTS_API_URL}/api/journalists/${key}/${journalistId}`)
    }

    async postJournalist(Journalist: IJournalistModel, inviteID: string): Promise<IBaseResult<boolean>>{
        return BaseService.PostData(`${process.env.REACT_APP_JOURNALISTS_API_URL}/api/journalists/${inviteID}`, Journalist)
    }

    async putJournalist(Journalist: IJournalistModel, journalistId: string): Promise<IBaseResult<boolean>>{
        return BaseService.PutData(`${process.env.REACT_APP_JOURNALISTS_API_URL}/api/journalists/${journalistId}`, Journalist)
    }

    async generateToken(session: any): Promise<IBaseResult<any>> {
        return BaseService.PostData(`${process.env.REACT_APP_JOURNALISTS_API_URL}/api/journalists/token`, session);
    }

    async getJournalistInvite(inviteID: string): Promise<IBaseResult<IJournalistInvite>> {
        return BaseService.GetData(`${process.env.REACT_APP_JOURNALISTS_API_URL}/api/journalists/invite/${inviteID}`)
    }

    async PostJournalistInvite(invite: IJournalistInvite): Promise<IBaseResult<boolean>> {
        return BaseService.PostData(`${process.env.REACT_APP_JOURNALISTS_API_URL}/api/journalists/invite`, invite)
    }
}