/* eslint-disable react/no-unescaped-entities */
import { XMarkIcon } from "@heroicons/react/24/outline";

const ResultsBanner = ({ results, keyword, header }: any) => {
  return <>
    <div className="md:flex md:items-center md:justify-between">
      <div className="min-w-0 flex-1 border-b ml-4 mr-4 border-gray-300">
        <h2 className="text-2xl font-bold leading-6 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight ">
          {header}
        </h2> 
        <p className="text-sm leading-6 ml-2 text-gray-800">
          <strong className="font-semibold">Showing {results.pageSize} of {results.totalItems}</strong>
        </p>
      </div>

    </div>
  </>
}

export default ResultsBanner;