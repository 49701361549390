/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useEffect, useState } from "react";
import { IArticleModel } from "../../../models/content-models/articleModels";
import { ArticleService } from "../../../services/articleSerivce";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import Loading from "../shared/loading";
import ArticleCard from "../rankings/article-card";
import { useNavigate } from "react-router-dom";
import AlertError from "../shared/alert-error";

const articleQueueColumn = () => {

    const articleService = new ArticleService();
    const navigate = useNavigate();
    const [queue, setQueue] = useState<IArticleModel[]>([]);
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(true);

    const getQueue = useCallback(async () => {
        const query = 'items=50&page=1&sort=desc';

        await articleService.getArticleQueue(query).then((response) => {
            setLoading(false);
            setSuccess(response.isSuccessful);
            if(response.isSuccessful){
                setQueue(response.results.pagedItems);
            }
            else{
                setMessage(response.errorMessage)
            }
        })
    }, []);

    const viewQueuedArticle = (e: any, article: IArticleModel) =>{
        e.preventDefault();
        navigate(`/queue/${article.articleID}`, {state: {article: article}})
    }

    useEffect(() => {
        getQueue();
    }, []);

    return <>

        {!loading && success ? <>
            <div className="m-2">
                {queue.map((article) => (
                    <button
                        type='button'
                        className='w-full'
                        onClick={(e) => viewQueuedArticle(e, article)}>
                        <ArticleCard article={article} />
                    </button>

                ))}
            </div>
        </> : message !== '' && !success ? <><AlertError message={message}/> </> : loading ? <><Loading /></> : <></>}
    </>
}

export default articleQueueColumn;