import { IBaseResult } from "../models/baseResults";
import { IArticleModel, IDailyNewspaperModel, IDailyNewspaperSubmission } from "../models/content-models/articleModels";
import BaseService from "./baseService";

export class ArticleService extends BaseService {

    async getArticleListDefunct(category: string): Promise<IBaseResult<any>> {
        // eslint-disable-next-line no-useless-concat
        return BaseService.GetData(process.env.REACT_APP_ARTICLES_API_URL + `/api/articles/category/${category}`);
    }

    async getArticleList(key: string, value: string, query: string): Promise<IBaseResult<any>> {
        // eslint-disable-next-line no-useless-concat
        return BaseService.GetData(`${process.env.REACT_APP_ARTICLES_API_URL}/api/articles/${key}/${value}/list?${query}`);
    }

    async getArticleCollection(queryString: string): Promise<IBaseResult<any>> {
        // eslint-disable-next-line no-useless-concat
        return BaseService.GetData(process.env.REACT_APP_ARTICLES_API_URL + `/api/articles?${queryString}`);
    }

    async getArticleQueue(queryString: string): Promise<IBaseResult<any>> {
        // eslint-disable-next-line no-useless-concat
        return BaseService.GetData(`${process.env.REACT_APP_ARTICLES_API_URL}/api/articles/queue?${queryString}`);
    }

    async getArticleInQueue(queryString: string, key: string, value: string): Promise<IBaseResult<any>> {
        // eslint-disable-next-line no-useless-concat
        return BaseService.GetData(`${process.env.REACT_APP_ARTICLES_API_URL}/api/articles/queue/${key}/${value}?${queryString}`);
    }

    async getArticle(key: string, value: string): Promise<IBaseResult<any>> {
        // eslint-disable-next-line no-useless-concat
        return BaseService.GetData(`${process.env.REACT_APP_ARTICLES_API_URL}/api/articles/article/${key}/${value}`)
    }

    async getRelatedArticleList(queryString: string): Promise<IBaseResult<any>> {
        // eslint-disable-next-line no-useless-concat
        return BaseService.GetData(`${process.env.REACT_APP_ARTICLES_API_URL}/api/articles/related` + `?${queryString}`);
    }

    async confirmArticle(articleId: string): Promise<IBaseResult<any>> {
        return BaseService.PutData(`${process.env.REACT_APP_ARTICLES_API_URL}/api/articles/queue/${articleId}`, '')
    }

    async rejectArticle(articleId: string): Promise<IBaseResult<any>> {
        return BaseService.DeleteData(`${process.env.REACT_APP_ARTICLES_API_URL}/api/articles/queue/${articleId}`)
    }

    async submitNewArticle(article: IArticleModel): Promise<IBaseResult<any>> {
        return BaseService.PostData(`${process.env.REACT_APP_ARTICLES_API_URL}/api/articles`, article)
    }

    async submitNewspaper(name: string, dailyNewspaper: IDailyNewspaperSubmission): Promise<IBaseResult<any>> {
        return BaseService.PostData(`${process.env.REACT_APP_ARTICLES_API_URL}/api/articles/newspaper/${name}?code=${process.env.REACT_APP_ARTICLES_TOKEN}`, dailyNewspaper)
    }
}