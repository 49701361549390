import { format } from "date-fns";
import { IArticleModel } from "../../../models/content-models/articleModels";

type Props = {
    article: IArticleModel
}

const articleCard = (props: Props) => {

    return <>
        <article key={props.article.articleID} className="flex max-w-xl m-1 flex-col items-start justify-between border-t">
            <div className="flex items-center gap-x-4 text-xs">
                <time className="text-gray-500">
                    {`${format(
                        props.article.submissionDate,
                        "EEEE, MMMM dd, yyyy"
                    )}`}
                </time>
            </div>
            <div className="group relative">
                <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                    <a >
                        <span className="absolute inset-0" />
                        {props.article.articleTitle}
                    </a>
                </h3>
            </div>
            <div className="relative mt-8 flex items-center">
                <img src={props.article.journalist.image.imageURL} alt="" className="h-10 w-10 rounded-full bg-gray-50" />
                <div className="text-sm leading-6">
                    <p className="font-semibold text-gray-900">
                        <a href={props.article.image.imageURL}>
                            <span className="absolute inset-0" />
                            {props.article.journalist.firstName} {props.article.journalist.lastName}
                        </a>
                    </p>
                    <p className="text-gray-600">{props.article.journalist.expertiseCategories}</p>
                </div>
            </div>
        </article>
    </>
}

export default articleCard;