import { XCircleIcon } from "@heroicons/react/24/outline";

const alertError = (message: any) => {

    return <><div className="rounded-none bg-red-200">
        <div className="flex">
            <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">{message.message}</h3>
            </div>
        </div>
    </div></>
}

export default alertError;