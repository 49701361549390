/* eslint-disable react/jsx-no-undef */
import { Provider } from "react-redux";
import "./App.css";
import Nav from "./app/nav/nav";
import { store } from "./redux/store";
import RouteHandler from "./routeHandler";

const App = () => {
  return (
    <>
      <Provider store={store}>
        <Nav />
        <RouteHandler />
      </Provider>
    </>
  );
};

export default App;
