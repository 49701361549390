import React, { useCallback, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { CognitoService } from '../services/cognitoService';
import { useCookies } from 'react-cookie';
import { jwtDecode } from 'jwt-decode';

const AdminProtectedRoute = ({ children }: any) => {
    const cognito = new CognitoService();
    const navigate = useNavigate();
    const [validUser, setValidUser] = useState(false);
    const sessionCookie = localStorage.getItem('session')

    const getCredentials = useCallback(async () => {
        await cognito.promiseAuthenticated().then((valid: boolean) => {
            setValidUser(valid);
            if (valid && sessionCookie !== null) {
                // get token
                const token = jwtDecode<any>(sessionCookie);
                
                if(token.role !== 'admin'){
                    navigate('/')
                }
                else{
                    return;
                }
            }
            else{
                // If there's no user, redirect to login page
                navigate('/');
            }
        });
    }, []);

    useEffect(() => {
        getCredentials();
      }, [validUser]);

    return children;
};

export default AdminProtectedRoute;