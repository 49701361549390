import { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import AddFeedSource from "./AddFeedSource"
import { RSSFeedService } from "../../../../services/rssFeedService"
import { IBaseResult } from "../../../../models/baseResults"
import { IFeedSource } from "../../../../models/rss-models/FeedSource"

const feed = [
    { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },
    { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },
    { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },
    { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },
    { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },
    { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },
    { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },
    { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },
    { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },
    { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },
    { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },
    { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },
    { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },
    { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },
    { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },

    // More feed...
]

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}


const FeedSource = () => {
    const navigate = useNavigate()

    const rssFeedService = new RSSFeedService();
    const [addFeed, setAddFeed] = useState(false)
    const [sources, setSources] = useState<IFeedSource[]>([] as IFeedSource[])
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState(150)
    const [page, setPage] = useState(1)

    const addSource = (e: any) => {
        e.preventDefault()
        setAddFeed(!addFeed)
    }

    // remove topic from list

    const removeTopic = (e: any) => {
        e.preventDefault()
    }

    const getSources = useCallback(async() =>{
        const queryString = `items=${items}&page=${page}`
        await rssFeedService.getRSSSources(queryString).then((response: IBaseResult<IFeedSource>) =>{
            console.log(response)
            if(response.isSuccessful){
                setSources(response.results.pagedItems);
            }
            else{
                throw new Error(response.errorMessage)
            }
        }).catch(err =>{
            setMessage(err.message)
        }).finally(() =>{
            setLoading(false)
        })
    },[])

    useEffect(() =>{
        getSources()
    },[])

    const displaySource = () => {
        return <>
            <div className="mt-1 flow-root">
                <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle">
                        <table className="min-w-full border-separate border-spacing-0">
                            <thead>
                                <tr>
                                    <th
                                        scope="col"
                                        className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                                    >

                                        Name
                                    </th>
                                    <th
                                        scope="col"
                                        className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                                    >

                                        Outlet
                                    </th>
                                    <th
                                        scope="col"
                                        className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                                    >

                                        Category
                                    </th>
                                    <th
                                        scope="col"
                                        className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                                    >
                                        URL
                                    </th>
                                    <th
                                        scope="col"
                                        className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                                    >
                                        <span className="sr-only">Remove</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {sources.map((source, personIdx) => (
                                    <tr key={source.feedName}>
                                        <td
                                            className={classNames(
                                                personIdx !== feed.length - 1 ? 'border-b border-gray-200' : '',
                                                'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8',
                                            )}
                                        >
                                            {source.feedName}
                                        </td>
                                        <td
                                            className={classNames(
                                                personIdx !== feed.length - 1 ? 'border-b border-gray-200' : '',
                                                'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8',
                                            )}
                                        >
                                            {source.newsOutlet}
                                        </td>
                                        <td
                                            className={classNames(
                                                personIdx !== feed.length - 1 ? 'border-b border-gray-200' : '',
                                                'hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell',
                                            )}
                                        >
                                            {source.category}
                                        </td>
                                        <td
                                            className={classNames(
                                                personIdx !== feed.length - 1 ? 'border-b border-gray-200' : '',
                                                'hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell',
                                            )}
                                        >
                                            <a href={source.feedURL} target="_blank">{source.feedURL}</a>
                                        </td>
                                        <td
                                            className={classNames(
                                                personIdx !== feed.length - 1 ? 'border-b border-gray-200' : '',
                                                'relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-8 lg:pr-8',
                                            )}
                                        >
                                            <button onClick={(e) => removeTopic(e)} className="text-red-600 hover:text-red-900">
                                                Remove
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    }

    return <>
        <div className="md:flex md:items-center md:justify-between">
            <div className="min-w-0 flex-1">
                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                    Feed Sources
                </h2>
            </div>
            <div className="mt-4 flex md:ml-4 md:mt-0">
                {!addFeed ? <>
                    <button
                        type="button"
                        onClick={(e) => addSource(e)}
                        className="ml-3 inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                    >
                        Add Source
                    </button>
                </> : <>
                    <button
                        type="button"
                        onClick={(e) => addSource(e)}
                        className="ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                    >
                        Cancel
                    </button>
                </>}

            </div>
        </div>
        <div>
            {addFeed ? <>
                <AddFeedSource />
            </> : <>
                {displaySource()}
            </>}
        </div>
    </>
}

export default FeedSource