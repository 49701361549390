"use client";
import { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { CognitoService } from "../../services/cognitoService";
import { LockClosedIcon } from "@heroicons/react/24/outline";
import { BeatLoader } from "react-spinners";
import useSession from "../../hooks/useSession";
import { AuthService } from "../../services/authService";
import AlertMessage from "../components/alerts/alert-error";
import { useNavigate, useParams } from "react-router-dom";
import { SignUpInput, SignUpOutput } from "aws-amplify/auth";
import { JournalistService } from "../../services/journalistService";
import { IBaseResult } from "../../models/baseResults";
import {
  IJournalistInvite,
  IJournalistModel,
  IMediaSources,
} from "../../models/content-models/journalistModels";
import logo from "../components/shared/logo";
import { IImageModel } from "../../models/content-models/imageModel";
import { IMetaData } from "../../models/content-models/articleModels";

const Signup = () => {
  const USER_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  const PWD_REGEX =
    /^(?=.*[a-z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}$/;
  const NAME_REGEX = /^.{2,}$/;

  const auth = new AuthService();
  const journalistService = new JournalistService();
  const cognito = new CognitoService();

  const { doesUserExist } = useSession();

  const { inviteID } = useParams();

  const [email, setEmail] = useState("");
  const [validName, setValidName] = useState(false);
  const [userFocus, setUserFocus] = useState(false);
  const navigate = useNavigate();

  const [pwd, setPwd] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [validFirst, setValidFirst] = useState(false);

  const [lastName, setLastName] = useState("");
  const [validLast, setValidLast] = useState(false);

  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);
  const cancelButtonRef = useRef(null);

  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [messageSeverity, setMessageSeverity] = useState(2);

  const [cognitoId, setCognitoId] = useState("");
  const [cognitoSuccess, setCognitoSuccess] = useState(false);

  const [user, setUser] = useCookies(["_new_user"]);
  const [journalist, setJournalist] = useCookies(["_temp_journalist"]);

  const validatePwdMatch = () => {
    //const v1 = USER_REGEX.test(email);
    const v2 = PWD_REGEX.test(pwd);
    if (v2 && matchPwd === pwd) {
      return true;
    } else {
      return false;
    }
  };

  const validateName = () => {
    const fn = NAME_REGEX.test(firstName);
    const ln = NAME_REGEX.test(lastName);

    if (fn && ln) {
      return true;
    } else {
      return false;
    }
  };

  const cognitoSignUp = async (signupInput: SignUpInput): Promise<void> => {
    console.log("cognito sign up");
    await cognito
      .signUp(signupInput)
      .then(async (cognitoResponse: SignUpOutput) => {
        setCognitoId(cognitoResponse.userId!);
        setCognitoSuccess(true);

        // create journalist record
        let expertise = [] as string[];
        let sources = [] as IMediaSources[];
        let image = {
          imageID: "",
          imageName: "",
          imageURL: "",
          uploadedBy: "",
          imageCaption: "",
          metaData: [] as IMetaData[],
          submissionDate: 0
        } as IImageModel
        console.log("cognito id: ", cognitoResponse.userId);

        const journalist = {
          cognitoID: cognitoResponse.userId,
          journalistID: "",
          email: email,
          firstName: firstName,
          middle: "",
          lastName: lastName,
          byline: "",
          sources: sources,
          roles: ["journalist"],
          image: image,
          phoneNumber: "",
          expertiseCategories: expertise,
        } as IJournalistModel;

        setJournalist("_temp_journalist", journalist);

        await journalistService
          .postJournalist(journalist, inviteID!)
          .then((response) => {
            setMessage("successfully submitted journalist");
          })
          .catch((err) => {
            throw err;
          });

        const user = {
          username: cognitoResponse.userId,
          pwd: pwd,
        } as any;

        setUser("_new_user", user);
        navigate(`/verify/${inviteID}`, { replace: true });
      })
      .catch((error) => {
        if (error instanceof Error) {
          // If the error message is in the format 'UsernameExistsException: User already exists'
          if (error.name === "UsernameExistsException") {
            console.error("Username already exists:", error.message);
            setMessage("Error - Contact Support - +1 (608) 609-5626");
          } else {
            console.error("Unhandled exception:", error.message);
          }
        } else {
          setMessage("Failed to subscribe");
          setLoading(false);
          throw error;
        }

        console.log("error", error);
      }).finally(() => {
        setLoading(false);
      })
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    setSubmitted(true);
    console.log("handle submit");

    const exists = await doesUserExist(email);
    const pwdResult = validatePwdMatch();
    const nameResult = validateName();

    console.log("exists: ", exists);

    if (pwdResult && nameResult && !exists) {
      // go to /checkout
      const user = {
        username: email,
        password: pwd,
      } as SignUpInput;

      localStorage.setItem("temp-email", email);

      // check if user exists
      console.log("valid match");
      cognitoSignUp(user);
    } else {
      setLoading(false);
      console.log("invalid match");
      if (exists) {
        setMessage("Invalid email");
      } else if (!pwdResult) {
        setMessage("Invalid Password");
      } else if (!nameResult) {
        setMessage("Invalid Name");
      }
    }
  };

  const getJournalistInvite = async () => {
    console.log("invite id: ", inviteID);
    await journalistService
      .getJournalistInvite(inviteID!)
      .then((response: IBaseResult<IJournalistInvite>) => {
        console.log("response", response);
        if (response.isSuccessful) {
          setEmail(response.result.email);
        } else {
        }
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getJournalistInvite();
  }, []);

  return (
    <>
      <div className="pt-12">
        {/* Checkout form */}
        <section
          aria-labelledby="payment-heading"
          className=" overflow-y-auto px-4 pb-16 pt-12 sm:px-6 sm:pt-16 lg:px-8 lg:pb-24 lg:pt-0"
        >
          <div className="mx-auto max-w-lg ">
            {/* <div className="flex justify-center items-center w-full sm:max-w-xs">
                        <img
                            width="300vw"
                            height="100vw"
                            src="/trendline-news-light.png"
                        />
                    </div> */}

            <form className="mt-6 bg-gray-200 p-5 shadow-md border border-gray-300">
              {logo()}
              {loading ? (
                <>
                  <BeatLoader
                    color="#FFFFFF"
                    loading={loading}
                    size={18}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </>
              ) : (
                <>
                  <p className="text-center text-gray-800 text-2xl">Sign Up</p>
                  <p className="text-center text-gray-800 text-md">
                    {" "}
                    * Required
                  </p>
                  <div className="grid grid-cols-12 gap-x-4 gap-y-6">
                    <div className="col-span-full">
                      <label
                        htmlFor="name-on-card"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email
                      </label>
                      <div className="mt-1">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          value={email}
                          readOnly={true}
                          required
                          autoComplete="email"
                          className="block w-full p-1 rounded-sm border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <div className="col-span-6">
                      <label
                        htmlFor="name-on-card"
                        className="block text-sm font-medium text-gray-700"
                      >
                        First Name *
                      </label>
                      <div className="mt-1">
                        <input
                          id="firstName"
                          name="firstName"
                          type="text"
                          onChange={(e) => setFirstName(e.target.value)}
                          value={firstName}
                          required
                          autoComplete="firstName"
                          className="block w-full p-1 rounded-sm border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <div className="col-span-6">
                      <label
                        htmlFor="name-on-card"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Last Name *
                      </label>
                      <div className="mt-1">
                        <input
                          id="lastName"
                          name="lastName"
                          type="text"
                          onChange={(e) => setLastName(e.target.value)}
                          value={lastName}
                          required
                          autoComplete="lastName"
                          className="block w-full p-1 rounded-sm border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <div className="col-span-full">
                      <label
                        htmlFor="card-number"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Password *
                      </label>
                      <div className="mt-1">
                        <input
                          id="password"
                          name="password"
                          type="password"
                          onChange={(e) => setPwd(e.target.value)}
                          value={pwd}
                          autoComplete="current-password"
                          required
                          className="block rounded-sm w-full p-1 border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                        />
                      </div>
                    </div>

                    <div className="col-span-full">
                      <label
                        htmlFor="card-number"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Confirm Password *
                      </label>
                      <div className="mt-1">
                        <input
                          id="confirmPassword"
                          name="confirmPassword"
                          type="password"
                          onChange={(e) => setMatchPwd(e.target.value)}
                          value={matchPwd}
                          autoComplete="current-password"
                          required
                          className="block rounded-sm w-full p-1 border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                        />
                      </div>
                    </div>
                  </div>
                  {!loading && submitted ? (
                    <>
                      {
                        <AlertMessage
                          message={message}
                          severity={messageSeverity}
                        />
                      }
                    </>
                  ) : (
                    <></>
                  )}
                  <button
                    type="submit"
                    className="mt-6 w-full rounded-sm border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    disabled={loading}
                    onClick={(e) => handleSubmit(e)}
                  >
                    Sign up
                  </button>

                  <p className="mt-6 flex justify-center text-sm font-medium text-gray-500">
                    Already have an account?&ensp;
                    <a
                      className="text-green-700 hover:text-green-600"
                      href={"/sign-in"}
                    >
                      Sign in
                    </a>
                  </p>
                </>
              )}
            </form>
          </div>
        </section>
      </div>
    </>
  );
};

export default Signup;
