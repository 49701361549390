import { IBaseResult } from "../models/baseResults";
import { IUserTopic } from "../models/content-models/selectedTopicModels";
import BaseService from "./baseService";

export class SelectedTopicService extends BaseService{
    async getTopic(JournalistID: string): Promise<IBaseResult<any>>{
        return BaseService.GetData(`${process.env.REACT_APP_JOURNALISTS_API_URL}/api/topic/${JournalistID}`)
    }

    async postTopic(topic: any, JournalistID: string): Promise<IBaseResult<any>>{
        return BaseService.PostData(`${process.env.REACT_APP_JOURNALISTS_API_URL}/api/topic/${JournalistID}`, topic)
    }

    async removeTopic(JournalistID: string, topicID: string): Promise<IBaseResult<IUserTopic>> {
        return BaseService.DeleteData(`${process.env.REACT_APP_JOURNALISTS_API_URL}/api/topic/${JournalistID}/${topicID}`)
    }

    async clearTopics(JournalistID: string): Promise<IBaseResult<IUserTopic>> {
        return BaseService.DeleteData(`${process.env.REACT_APP_JOURNALISTS_API_URL}/api/topic/${JournalistID}`)
    }
}