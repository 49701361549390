/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { User } from "../../models/user-models/UserModel";
import { CognitoService } from "../../services/cognitoService";
import { AuthService } from "../../services/authService";
import AlertError from "../components/shared/alert-error";
import logo from "../components/shared/logo";
import { JournalistService } from "../../services/journalistService";
import { SignInInput } from "aws-amplify/auth";

const signIn = () => {
  const location = useLocation();
  const navigate = useNavigate();
  document.title = 'Sign In';
  const from = location.state?.from?.pathname || "/";

  const cognito = new CognitoService();
  const journalistService = new JournalistService();
  const authService = new AuthService();

  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [authenticated, setAuthenticated] = useState(false);


  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const checkCurrentLogin = async () => {

    await cognito.promiseAuthenticated().then((response) => {
      setAuthenticated(response);
      if (response) {
        navigate('/');
      }
    })
  };

  useEffect(() => {
    setErrMsg("");
  }, [email, pwd]);

  useEffect(() => {
    checkCurrentLogin();
  })

  const isDarkMode = () =>
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

  const generateJwt = async () => {
    await authService
      .getUserMetaData()
      .then(async (result) => {

        await cognito.getUser().then(async (response: any) => {
          let sessionDetails = {
            SessionId: result,
            Role: '',
            JournalistId: '',
            CognitoUsername: response.username
          };

          await journalistService
            .generateToken(sessionDetails)
            .then((results) => {
              if(results.isSuccessful){
                localStorage.setItem("session", results.result);
                navigate('/', { replace: true });
              }
              else{
                setErrMsg(results.errorMessage)
              }

            })
            .catch((error) => {
              throw error;
            });
        }).catch((err: any) => {
          console.log(err)
          setErrMsg(err.Message);
        })
      })
      .catch((error) => {
        console.log(error)
        throw error;
      });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    const signIn = {
      username: email,
      password: pwd
  } as SignInInput

    try {
      await cognito
        .signIn(signIn)
        .then(async (response) => {
          generateJwt()
        })
        .catch((error: any) => {
          console.log(error)
          setLoading(false);
          if (error.type === "UserNotConfirmedException") {
            localStorage.setItem('temp-email', email);
            navigate("/verify", { replace: true });
          } else {
            setErrMsg("Login Failed");
          }
        });
    } catch (err: any) {
      console.log(err)
      setLoading(false);
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
      }
    }
  };
  return <>
    <div className="  justify-center px-6 py-4 ">

      <div className="grid grid-cols-3">
        <div className="col-span1" />
        <div className="bg-gray-200 p-2 rounded-md border border-gray-400 shadow-xl">

          {logo()}
          <h2 className="mt-4 text-center text-4xl font-bold leading-9 tracking-tight text-gray-900">
            Content Manager
          </h2>
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm ">
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 ">
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="username"
                    autoComplete="off"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    required
                    className="block w-full rounded-md border border-gray-500 bg-white/5 py-1.5 px-1 text-gray-900 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900 ">
                    Password
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    type="password"
                    id="password"
                    onChange={(e) => setPwd(e.target.value)}
                    value={pwd}
                    required
                    className="block w-full rounded-md bg-white/5 py-1.5 px-1 border border-gray-500 text-gray-900 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              {errMsg !== '' ? <>{<AlertError message={errMsg} />}</> : <></>}
              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-green-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <BeatLoader
                        color="#FFFFFF"
                        loading={loading}
                        size={18}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    </>
                  ) : (
                    <>Sign In</>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>
  </>
}

export default signIn;