export class NewsCategories{
 
    static readonly NEWS_CATEGORIES = [
        {
            category : "Milwaukee",
            href:"milwaukee",
            subcategories: [
                "Brewers",
                "Bucks",
                "Business",
                "Events",
                "Crime",
                "Politics"
            ]
        },
        {
            category : "Business",
            href:"business",
            subcategories: [
                "Technology",
                "Media",
                "Airlines",
                "Automotive",
                "Healthcare",
                "Energy",
                "Retail",
                "Transportation"
            ]
        },
        {
            category : "Politics",
            href:"politics",
            subcategories: [
                "Elections",
                "Congress",
                "Supreme Court",
                "Presidential"
            ]
        },
        {
            category : "Tech",
            href:"tech",
            subcategories: [
                "AI",
                "Entrepreneur",
                "Entertainment",
                "Personal Tech",
                "Software Development"
            ]
        },
        {
            category : "US News",
            href:"us-news",
            subcategories: [
                "U.S. Events",
                "Crime",
                "U.S. Politics"
            ]
        },
        {
            category : "World News",
            href:"world-news",
            subcategories: [
                "Americas",
                "Europe",
                "Middle East",
                "Asia",
            ]
        },
        {
            category : "People",
            href:"people",
            subcategories: [
                ""
            ]
        },
        {
            category : "Opinion",
            href:"opinion",
            subcategories: [
                ""
            ]
        }
    ]

    static readonly FEED_CATEGORIES = [
        {
            Category: "Headlines",
            Subcategories: [
                ""
            ]
        },
        {
            Category: "Finance",
            Subcategories: [
                "Market News",
                "IPO",
                "Economics",
                ""
            ]
        },
        {
            Category: "Business",
            Subcategories: [
                ""
            ]
        },
        {
            Category: "Tech",
            Subcategories: [
                ""
            ]
        },
        {
            Category: "Politics",
            Subcategories: [
                "DOD",
                "Defense",
                "Congress"
            ]
        },
        {
            Category: "World",
            Subcategories: [
                "US News",

            ]
        },
    ]
}