/* eslint-disable react-hooks/rules-of-hooks */
import { useState } from "react";
import ArticleQueueColumn from "../article-queue/article-queue-column";
import DynamicFeedTable from "../shared/tables/dynamic-feed-table";
import { useNavigate } from "react-router-dom";
import PressReleaseTable from "../shared/tables/press-release-table";
import SelectedTopicsTable from "../selected-topics/selected-topics-table";

const tabs = [
    { name: 'News Feed', href: '/content-manager', current: true },
    { name: 'Press Releases', href: '/videos', current: false },
]

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
  }

const dashboard = () => {

    const navigate = useNavigate()
    const [selectedTab, setSelectedTab] = useState('News Feed')

    const expandLeads = (e: any) => {
        e.preventDefault()
        switch(selectedTab){
            case tabs[0].name :
                navigate('/top-leads')
                break;
            case tabs[1].name :
                navigate('/press-releases')
                break;
        }

    }

    const expandSelectedLeads = (e: any) => {
        e.preventDefault()
        navigate('/selected-leads')
    }

    const renderTabs = () => {
        console.log('re render tabs')
        return <>
            {(() => {
                console.log(selectedTab)
                switch (selectedTab) {
                    case 'News Feed':
                        return <DynamicFeedTable />
                    case 'Press Releases':
                        return <PressReleaseTable />
                }
            })()}
        </>
    }

    return <>
        <div className="py-4">
            <header>
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">Dashboard</h1>
                </div>
            </header>
            <main>
                <div className="sm:px-2 lg:px-2">
                    <div className="sm:hidden">
                        <label htmlFor="current-tab" className="sr-only">
                            Select a tab
                        </label>
                        <select
                            id="current-tab"
                            name="current-tab"
                            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            defaultValue={tabs.find((tab) => tab.current)?.name}
                        >
                            {tabs.map((tab) => (
                                <option key={tab.name}>{tab.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="hidden sm:block">
                        <nav className="-mb-px flex space-x-8">
                            {tabs.map((tab) => (
                                <button
                                    key={tab.name}
                                    onClick={() => setSelectedTab(tab.name)}
                                    className={classNames(
                                        tab.name === selectedTab
                                            ? 'border-indigo-500 text-indigo-600'
                                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                        'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium'
                                    )}
                                    aria-current={tab.current ? 'page' : undefined}
                                >
                                    {tab.name}
                                </button>
                            ))}
                        </nav>
                    </div>
                    <div className="grid grid-cols-4">
                        <div className="col-span-3 grow pr-2">
                            <div className="border rounded-md">
                                <div className="flex mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                                    <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900">Top Headlines</h1>
                                    <button onClick={(e: any) => expandLeads(e)} className="text-green-600 hover:text-green-800">expand</button>
                                </div>
                                <div className="h-96 overflow-scroll">
                                    {renderTabs()}
                                </div>

                            </div>
                            <div className="border rounded-md mt-10">
                                <div className="flex mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                                    <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900">Selected Headlines</h1>
                                    <button onClick={(e: any) => expandSelectedLeads(e)} className="text-green-600 hover:text-green-800">expand</button>
                                </div>
                                <div className="h-96 overflow-scroll">
                                    <SelectedTopicsTable />
                                </div>
                            </div>
                        </div>
                        <div className="grow col-span-1">
                            <div className="border rounded-md">
                                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                                    <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">Article Queue</h1>
                                </div>
                                <ArticleQueueColumn />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    </>
}

export default dashboard;