/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from "react";
import IFile from "../../../models/request-models/IFile";
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
  PhotoIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import AlertError from "../shared/alert-error";
import { S3BucketService } from "../../../services/s3BucketService";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { IArticleModel } from "../../../models/content-models/articleModels";
import { updateArticleSubmission } from "../../../redux/slice/submitArticleSlice";
import { IImageModel } from "../../../models/content-models/imageModel";
import { ImageService } from "../../../services/imageService";
import { IBaseResult, IPagedResults } from "../../../models/baseResults";
import { Dialog, Switch } from "@headlessui/react";

const imageUpload = ({ bucketName }: any) => {
  const imageOnLoad = JSON.parse(localStorage.getItem("current-image")!);
  const imageService = new ImageService();
  const [useExistingPhoto, setUseExistingPhoto] = useState(true);
  const [currentImage, setCurrentImage] = useState<any>(imageOnLoad || {});
  const [previewImage, setPreviewImage] = useState<string>("");

  const [imageList, setImageList] = useState<IImageModel[]>(
    [] as IImageModel[]
  );

  // results states
  const [pagedResults, setPagedResults] = useState(
    {} as IPagedResults<IArticleModel>
  );
  const [previousDisabled, setPreviousDisabled] = useState(true);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [items, setItems] = useState(18);

  const [openModal, setOpenModal] = useState(false);
  const [imageSearchList, setImageSearchList] = useState<IArticleModel[]>([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [imageQuery, setImageQuery] = useState("");
  const [progress, setProgress] = useState<number>(0);
  const [presignedUrl, setPresignedUrl] = useState("");
  const [message, setMessage] = useState<string>("");
  const [error, setError] = useState<string>("");

  const articleSubmission = useSelector(
    (state: RootState) => state.articleSubmission?.article
  );
  const dispatch = useDispatch();

  const [imageCaption, setImageCaption] = useState(
    articleSubmission.image.imageCaption || ""
  );

  const [imageInfos, setImageInfos] = useState<Array<IFile>>(
    imageOnLoad || ({} as Array<IFile>)
  );

  const selectImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files as FileList;
    setCurrentImage(selectedFiles?.[0]);
    localStorage.setItem("current-image", JSON.stringify(selectedFiles?.[0]));
    const urlFile = URL.createObjectURL(selectedFiles?.[0]);
    setPreviewImage(urlFile);
    setProgress(0);
  };

  const upload = async (e: any) => {
    e.preventDefault();
    if (!currentImage) return;

    try {
      const s3BucketService = new S3BucketService();
      await s3BucketService
        .putNewImage(bucketName, currentImage)
        .then(async (response) => {
          setMessage("Image Uploaded");

          const image = {
            imageID: "",
            imageName: response,
            imageURL: `https://mkepost-image-bucket.s3.us-east-2.amazonaws.com/${response}`,
            uploadedBy: "",
            imageCaption: "",
            metaData: [],
            submissionDate: 0,
          } as IImageModel;

          await imageService
            .PostImage(image)
            .then(async (response: IBaseResult<IImageModel>) => {
              if (response.isSuccessful) {
                const article = {
                  articleTitle: articleSubmission.articleTitle,
                  articleID: articleSubmission.articleID,
                  articleType: articleSubmission.articleType,
                  categories: articleSubmission.categories,
                  subCategory: articleSubmission.subCategory,
                  articleLead: articleSubmission.articleLead,
                  content: articleSubmission.content,
                  plainText: articleSubmission.plainText,
                  image: response.result,
                  journalist: articleSubmission.journalist,
                  premiumContent: articleSubmission.premiumContent,
                  sponsoredContent: articleSubmission.sponsoredContent,
                  submissionDate: articleSubmission.submissionDate,
                  metaData: articleSubmission.metaData,
                  publisher: articleSubmission.publisher,
                } as IArticleModel;

                console.log("saved article", article);

                dispatch(updateArticleSubmission(article));
              } else {
                setMessage(response.errorMessage);
              }
            });
        })
        .catch((err) => {
          console.error("Error uploading file:", err);
          setError("Failed to upload file to S3 Bucket");
        });
    } catch (err) {
      setError("Failed to upload file to S3 Bucket");
    }
  };

  const getImages = async (items: number, page: number) => {
    const query = `items=${items}&page=${page}&sort=desc`;
    await imageService
      .GetAllImages(query)
      .then((response: IBaseResult<IImageModel>) => {
        if (response.isSuccessful) {
          setImageList([...imageList, ...response.results.pagedItems]);
        } else {
          throw new Error(response.errorMessage);
        }
      })
      .catch((err) => {
        setMessage(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const filteredImage: any =
    imageQuery === ""
      ? imageSearchList
      : imageSearchList.filter((article) => {
          return article.image.imageName
            .toLowerCase()
            .includes(filteredImage.toLowerCase());
        });

  const remove = (e: any) => {
    e.preventDefault();
    setCurrentImage(null);
    setPreviewImage("");
    localStorage.removeItem("presigned-url");
  };

  const openImageModal = (e: any) => {
    e.preventDefault();
    console.log("preview");
    setOpenModal(true);
  };

  const calcualtePageNavDisplay = (
    pagedResults: IPagedResults<IArticleModel>
  ) => {
    if (pagedResults.page === 1) {
      setPreviousDisabled(true);
      setNextDisabled(false);
    } else if (pagedResults.page === pagedResults.totalPages) {
      setNextDisabled(true);
      setPreviousDisabled(false);
    } else {
      setPreviousDisabled(false);
      setNextDisabled(false);
    }
  };

  const renderPreviousPage = (e: any) => {
    e.preventDefault();
    console.log("previous page", page - 1);
    setPage(page - 1);
    let previousPage = page - 1;
    getImages(items, previousPage);
  };

  const selectImageFromModal = (e: any, imageID: string) => {
    e.preventDefault();
    console.log("selected Image", e);

    const image = imageList.find(
      (image: IImageModel) => image.imageID === imageID
    );

    const article = {
      articleTitle: articleSubmission.articleTitle,
      articleID: articleSubmission.articleID,
      articleType: articleSubmission.articleType,
      categories: articleSubmission.categories,
      subCategory: articleSubmission.subCategory,
      articleLead: articleSubmission.articleLead,
      content: articleSubmission.content,
      plainText: articleSubmission.plainText,
      image: image,
      journalist: articleSubmission.journalist,
      premiumContent: articleSubmission.premiumContent,
      sponsoredContent: articleSubmission.sponsoredContent,
      submissionDate: articleSubmission.submissionDate,
      metaData: articleSubmission.metaData,
      publisher: articleSubmission.publisher,
    } as IArticleModel;

    console.log("saved article", article);

    dispatch(updateArticleSubmission(article));
    setOpenModal(false);
  };

  const renderNextPage = () => {
    console.log("next page", page + 1);
    setPage(page + 1);
    let nextPage = page + 1;
    getImages(items, nextPage);
  };

  const updatePageSize = (pageSize: number) => {
    setItems(pageSize);
    getImages(pageSize, page);
  };

  const saveArticle = () => {
    let savedArticle = {
      articleTitle: articleSubmission.articleTitle,
      articleID: "",
      articleType: articleSubmission.articleType,
      categories: articleSubmission.categories,
      subCategory: articleSubmission.subCategory,
      articleLead: articleSubmission.articleLead,
      content: articleSubmission.content,
      plainText: articleSubmission.plainText,
      image: articleSubmission.image,
      journalist: articleSubmission.journalist,
      premiumContent: articleSubmission.premiumContent,
      sponsoredContent: articleSubmission.sponsoredContent,
      submissionDate: 0,
      metaData: articleSubmission.metaData,
      publisher: articleSubmission.publisher,
    } as IArticleModel;

    console.log("saved article", savedArticle);

    dispatch(updateArticleSubmission(savedArticle));
  };

  const removeSelectedImage = (e: any) => {
    e.preventDefault();
    const image = {
      imageID: "",
      imageName: "",
      imageURL: "",
      uploadedBy: "",
      imageCaption: "",
      metaData: [],
      submissionDate: 0,
    } as IImageModel;

    const article = {
      articleTitle: articleSubmission.articleTitle,
      articleID: articleSubmission.articleID,
      articleType: articleSubmission.articleType,
      categories: articleSubmission.categories,
      subCategory: articleSubmission.subCategory,
      articleLead: articleSubmission.articleLead,
      content: articleSubmission.content,
      plainText: articleSubmission.plainText,
      image: image,
      journalist: articleSubmission.journalist,
      premiumContent: articleSubmission.premiumContent,
      sponsoredContent: articleSubmission.sponsoredContent,
      submissionDate: articleSubmission.submissionDate,
      metaData: articleSubmission.metaData,
      publisher: articleSubmission.publisher,
    } as IArticleModel;

    console.log("saved article", article);

    dispatch(updateArticleSubmission(article));
  };

  useEffect(() => {
    saveArticle();
  }, [imageCaption]);

  useEffect(() => {
    getImages(items, page);
  }, []);

  const selectExistingModal = () => {
    return (
      <>
        <Dialog
          open={openModal}
          onClose={setOpenModal}
          className="relative z-10 bg-gray-200"
        >
          <Dialog.Backdrop className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in" />

          <div className="fixed inset-0 z-10 w-full overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left max-w-4xl shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95">
                <div>
                  <div className=" text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold text-gray-900"
                    ></Dialog.Title>
                    <div className="mt-2 grid grid-cols-3">
                      {imageList.map((image: IImageModel) => (
                        <button
                          className="col-span-1 p-2 items-start justify-between hover:bg-gray-300"
                          value={image.imageID}
                          onClick={(e) =>
                            selectImageFromModal(e, image.imageID)
                          }
                        >
                          <div key={image.imageID} className="flex flex-col ">
                            <p className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100">
                              {image.imageID}
                            </p>
                            <div className="relative w-full">
                              <img
                                alt=""
                                src={image.imageURL}
                                className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                              />
                              <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                            </div>
                            <div className="max-w-xl">
                              <div className="mt-8 flex items-center gap-x-4 text-xs">
                                <p className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100">
                                  {image.imageCaption}
                                </p>
                              </div>
                            </div>
                          </div>
                        </button>
                      ))}
                    </div>
                    <button
                      type="button"
                      onClick={(e) => renderNextPage()}
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                    >
                      Load More
                    </button>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                  >
                    Continue
                  </button>
                  <button
                    type="button"
                    data-autofocus
                    onClick={() => setOpenModal(false)}
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </div>
          </div>
        </Dialog>
      </>
    );
  };

  return (
    <>
      <div className="flex items-center justify-between">
        <span className="flex flex-grow flex-col">
          <label className="text-sm font-medium leading-6 text-gray-900">
            Use Existing Photo
          </label>
        </span>
        <Switch
          checked={useExistingPhoto}
          onChange={setUseExistingPhoto}
          className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600"
        >
          <span
            aria-hidden="true"
            className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
          />
        </Switch>
      </div>
      {useExistingPhoto ? (
        <>
          {articleSubmission.image.imageID !== "" ? (
            <>
              <img
                className=" rounded-xl bg-gray-900"
                src={articleSubmission?.image.imageURL}
                alt={"article image"}
              />
              <button
                onClick={(e) => removeSelectedImage(e)}
                className="rounded-md bg-red-600 mt-1 w-full ml-4 text-nowrap sticky top-20 h-10 p-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Deselect Image
              </button>
            </>
          ) : (
            <>
              <button
                onClick={(e) => openImageModal(e)}
                className="rounded-md bg-indigo-600 mt-1 ml-4 text-nowrap sticky top-20 h-10 p-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Select Image
              </button>
            </>
          )}
        </>
      ) : (
        <>
          <div className="col-span-full">
            <label
              htmlFor="cover-photo"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Cover photo
            </label>
            <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
              <div className="text-center">
                {previewImage ? (
                  <div className="h-96 w-96">
                    <img className="preview mt-2" src={previewImage} alt="" />
                  </div>
                ) : (
                  <>
                    <PhotoIcon
                      className="mx-auto h-12 w-12 text-gray-300"
                      aria-hidden="true"
                    />
                    <div className="mt-4 flex text-sm leading-6 text-gray-600">
                      <label className="btn btn-default p-0">
                        <input
                          type="file"
                          accept="image/*"
                          onChange={selectImage}
                        />
                      </label>
                      <p className="pl-1">or drag and drop</p>
                    </div>
                    <p className="text-xs leading-5 text-gray-600">
                      PNG, JPG, GIF up to 10MB
                    </p>
                  </>
                )}
              </div>
            </div>
            <div className="sm:col-span-4">
              <label
                htmlFor="username"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Image Caption
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    type="text"
                    name="title"
                    id="title"
                    autoComplete="title"
                    onChange={(e) => setImageCaption(e.target.value)}
                    value={imageCaption}
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            {error !== "" ? <>{<AlertError errorMessage={error} />}</> : <></>}

            <div className="flex justify-end">
              <button
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm m-2 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                disabled={!currentImage}
                onClick={(e) => upload(e)}
              >
                Upload Image
              </button>
              <button
                className="rounded-md bg-transparent border border-red-500 px-3 py-2 text-sm m-2 font-semibold text-red-500 shadow-sm hover:bg-red-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                disabled={!currentImage}
                onClick={(e) => remove(e)}
              >
                Remove Image
              </button>
            </div>

            {message && (
              <div className="alert alert-secondary mt-3" role="alert">
                {message}
              </div>
            )}

            <p>
              presigned url:{" "}
              {presignedUrl !== "" ? (
                <>
                  <a href={`${presignedUrl}`}>Image Url</a>
                </>
              ) : (
                <></>
              )}
            </p>

            {imageInfos.length > 0 && (
              <div className="card mt-3">
                <div className="card-header">List of Images</div>
                <ul className="list-group list-group-flush">
                  {imageInfos.map((img, index) => (
                    <li className="list-group-item" key={index}>
                      <p>
                        <a href={img.url}>{img.name}</a>
                      </p>
                      <img src={img.url} alt={img.name} height="80px" />
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </>
      )}

      {selectExistingModal()}
    </>
  );
};

export default imageUpload;
