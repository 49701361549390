import parse from "html-react-parser";
import AdBanner from "../ads-placeholder/AdBanner";
import { format } from "date-fns";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../../redux/store";
import { IArticleModel } from "../../../models/content-models/articleModels";

type Props = {
    article: IArticleModel
}

const ArticlePreview = ({article}: Props) => {

  const articleDisplay = () => {
    return (
      <>
        {/* advertisement banner */}
        <div className="grid-rows-1 border-t border-gray-400 border-b mb-1">
          {/* horizontal banner */}
          {/* <AdBanner adSlot={"1834246524"} format={"auto"} responsive={"true"} /> */}
          <AdBanner
            data-ad-slot={"1834246524"}
            data-ad-format={"auto"}
            data-full-width-responsive={"true"}
          />
        </div>
        <article className="relative isolate overflow-hidden lg:pl-6 px-6 lg:overflow-visible lg:px-0 lg:border-r border-gray-300">
          <div className="mx-auto  lg:mx-0 lg:max-w-none ">
            <div className="">
              <div className="lg:pr-4">
                <div className="lg:max-w-lg">
                  <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    {article?.articleTitle}
                  </h1>
                  <p className="text-base font-semibold leading-7 text-green-600">{`${format(
                    article?.submissionDate || new Date(),
                    "EEEE, MMMM dd, yyyy"
                  )}`}</p>
                  <img
                    className=" rounded-xl bg-gray-900"
                    src={article?.image.imageURL}
                    width={500}
                    height={500}
                    alt={"article image"}
                  />
                  <p className="italic">{article?.image.imageCaption}</p>
                  <p className="mt-6 text-xl leading-8 text-gray-700">
                    {article?.articleLead}
                  </p>

                  <h5 className="mt-6 text-xl leading-8 text-gray-700">
                    By {article?.journalist?.firstName}{" "}
                    {article?.journalist?.middle}{" "}
                    {article?.journalist?.lastName}
                  </h5>
                </div>
              </div>
            </div>

            <AdBanner
              data-ad-slot={"6456017815"}
              data-ad-format={"fluid"}
              data-full-width-responsive={"autorelaxed"}
            />

            <div className=" lg:col-span-1 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-8xl lg:grid-cols-1 lg:gap-x-2 lg:px-4">
              <div className="lg:pr-4">
                <div className="prose child">
                  {parse(`${article?.content}`)}
                </div>
              </div>
            </div>
            <div className="relative mt-8 ms-8 flex items-center gap-x-4">
              <div className="text-md leading-6 p-4">
                <img
                  src={article?.journalist?.image.imageURL}
                  alt={"article image"}
                  width={150}
                  height={150}
                  className="avatar-img rounded-full"
                />
                <p className="font-semibold text-xl text-gray-900200 ">
                  <a
                    className="hover:bg-gray-200"
                    href={`#`}
                  >
                    <span className="absolute inset-0" />
                    {article?.journalist?.firstName}{" "}
                    {article?.journalist?.middle}{" "}
                    {article?.journalist?.lastName}
                  </a>
                </p>
                <p className="text-gray-600">
                  {article?.journalist?.expertiseCategories}
                </p>
                <p className="mt-6 text-xl leading-8 text-gray-700">
                  {article?.journalist?.byline}
                </p>
              </div>
            </div>
          </div>
        </article>
      </>
    );
  };

  return <>
        <div className="grid lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-1 bg-gray-100 gap-1">
        <div className="lg:col-span-1 md:col-span-1 items-center justify-center">
            Share Buttons
        </div>
        <div className="lg:col-span-2 md:col-span-2 items-center justify-center">
          {articleDisplay()}
        </div>
        <div className="lg:col-span-1 md:col-span-1">
          Popular Articles
        </div>
      </div>
  </>;
};

export default ArticlePreview;
