import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import { IArticleModel } from "../../../models/content-models/articleModels";
import { useCallback, useEffect, useState } from "react";

import { format } from "date-fns";
import { ArticleService } from "../../../services/articleSerivce";
import { IBaseResult, IPagedResults } from "../../../models/baseResults";
import ResultsBanner from "./pagination/results-banner";
import LoadingIndicator from "./loading";

type Props = {
  keyword: string;
  value: string;
};

const ArticleResults = (props: Props) => {
  console.log("search term", props.keyword);
  console.log("search value", props.value);
  const articleService = new ArticleService();

  const [pagedResults, setPagedResults] = useState(
    {} as IPagedResults<IArticleModel>
  );
  const [previousDisabled, setPreviousDisabled] = useState(true);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  const [page, setPage] = useState(1);
  const [items, setItems] = useState(12);
  const [sort, setSort] = useState("desc");

  const getArticles = useCallback(async (size: number, page: number) => {
    console.log("getting articles", props.keyword);
    const queryString = `sort=${sort}&items=${items}&page=${page}`;
    await articleService
      .getArticleList("JournalistID", props.value, queryString)
      .then((response: IBaseResult<IArticleModel>) => {
        console.log("article search", response);
        if (response.isSuccessful) {
          setPagedResults(response.results);
          calcualtePageNavDisplay(response.results);
        } else {
          throw new Error(response.errorMessage);
        }
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const calcualtePageNavDisplay = (
    pagedResults: IPagedResults<IArticleModel>
  ) => {
    if (pagedResults.page === 1) {
      setPreviousDisabled(true);
      setNextDisabled(false);
    } else if (pagedResults.page === pagedResults.totalPages) {
      setNextDisabled(true);
      setPreviousDisabled(false);
    } else {
      setPreviousDisabled(false);
      setNextDisabled(false);
    }
  };

  const renderPreviousPage = (e: any) => {
    e.preventDefault();
    console.log("previous page", page - 1);
    setPage(page - 1);
    let previousPage = page - 1;
    getArticles(items, previousPage);
  };

  const renderNextPage = (e: any) => {
    e.preventDefault();
    console.log("next page", page + 1);
    setPage(page + 1);
    let nextPage = page + 1;
    getArticles(items, nextPage);
  };

  const updatePageSize = (pageSize: number) => {
    setItems(pageSize);
    getArticles(pageSize, page);
  };

  useEffect(() => {
    getArticles(items, page);
  }, []);

  const divider = () => {
    return (
      <>
        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center">
            <span className="bg-white px-2 text-gray-500">
              <PlusIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
            </span>
          </div>
        </div>
      </>
    );
  };

  const pagedNav = () => {
    return (
      <>
        <nav className="flex items-center justify-between border-t border-gray-300 px-4 sm:px-0 lg:m-4">
          <div className="-mt-px flex w-0 flex-1">
            {!previousDisabled ? (
              <button
                className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-lg font-medium text-gray-600 hover:border-gray-300 hover:text-gray-500"
                disabled={previousDisabled}
                onClick={(e) => renderPreviousPage(e)}
              >
                <ArrowLongLeftIcon
                  className="mr-3 h-5 w-5 text-gray-600"
                  aria-hidden="true"
                />
                Previous
              </button>
            ) : (
              <></>
            )}
          </div>
          <p className="text-gray-600">
            Page {pagedResults.page} out of {pagedResults.totalPages}
          </p>
          <div className="-mt-px flex w-0 flex-1 justify-end">
            {!nextDisabled ? (
              <button
                className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-lg font-medium text-gray-600 hover:border-gray-300 hover:text-gray-500"
                disabled={nextDisabled}
                onClick={(e) => renderNextPage(e)}
              >
                Next
                <ArrowLongRightIcon
                  className="ml-3 h-5 w-5 text-gray-600"
                  aria-hidden="true"
                />
              </button>
            ) : (
              <></>
            )}
          </div>
        </nav>
      </>
    );
  };

  return (
    <>
      <ResultsBanner
        results={pagedResults}
        keyword={props.keyword}
        header={"Article Results"}
      />
      {!loading ? (
        <>
          <div className="bg-white py-24 sm:py-32 border-t">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                {pagedResults?.pagedItems?.map((article) => (
                  <>
                    <article
                      key={article.articleID}
                      className="flex flex-col items-start justify-between"
                    >
                      <div className="relative w-full">
                        <img
                          alt=""
                          src={article.image.imageURL}
                          className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                        />
                        <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                      </div>
                      <div className="max-w-xl">
                        <div className="mt-8 flex items-center gap-x-4 text-xs">
                          {/* <time dateTime={post.datetime} className="text-gray-500">
                    {post.date}
                  </time> */}
                          <a
                            href={`https://mkepost.com/category/${article.categories[0]}`}
                            className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
                          >
                            {article.categories[0]} - {article.subCategory}
                          </a>
                        </div>
                        <div className="group relative">
                          <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                            <a
                              href={`https://mkepost.com/article/${article.articleID}`}
                            >
                              <span className="absolute inset-0" />
                              {article.articleTitle}
                            </a>
                          </h3>
                          <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">
                            {article.articleLead}
                          </p>
                        </div>
                        <div className="relative mt-8 flex items-center gap-x-4">
                          <img
                            alt=""
                            src={article.journalist.image.imageURL}
                            className="h-10 w-10 rounded-full bg-gray-100"
                          />
                          <div className="text-sm leading-6">
                            <p className="font-semibold text-gray-900">
                              <div>
                                <span className="absolute inset-0" />
                                {article.journalist.firstName}{" "}
                                {article.journalist.lastName}
                              </div>
                            </p>
                            <p className="text-gray-600">
                              {article.journalist.expertiseCategories[0]}
                            </p>
                          </div>
                        </div>
                      </div>
                    </article>
                  </>
                ))}
              </div>
            </div>
          </div>
          {pagedNav()}
        </>
      ) : (
        <>
          <LoadingIndicator />
        </>
      )}
    </>
  );
};

export default ArticleResults;
