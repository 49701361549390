/* eslint-disable react-hooks/rules-of-hooks */
import { Disclosure, Menu, Transition } from "@headlessui/react"
import React, { Fragment, useCallback, useEffect, useState } from "react"
import { CognitoService } from "../../services/cognitoService"
import UserNav from "./user-nav"
import UnauthorizedNav from "./unauthorized-nav"
import useSession from "../../hooks/useSession"
import { JournalistService } from "../../services/journalistService"
import { useLocation } from 'react-router-dom';
import { useSelector } from "react-redux"

const nav = () => {
    const cognitoService = new CognitoService();
    const journalistService = new JournalistService()

    const [authorized, setAuthorized] = useState(false);
    const currentRoute = useSelector((state: any) => state.route.currentRoute);

    const authorize = useCallback(async () => {
        await cognitoService.promiseAuthenticated().then((response: boolean) => {
            setAuthorized(response);


        })
    }, [cognitoService]);

    useEffect(() => {
        authorize();
    }, [currentRoute])

    return <>
        <div className="bg-white z-40 shadow lg:sticky top-0 mx-auto w-full px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 justify-between">
                {authorized ? <>
                    <UserNav />
                </> : <>
                    <UnauthorizedNav />
                </>}
            </div>
        </div>
    </>
}

export default nav;