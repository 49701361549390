import { useState } from "react";
import { RSSFeedService } from "../../../../services/rssFeedService"
import AlertError from "../../shared/alert-error"
import { IFeedSource } from "../../../../models/rss-models/FeedSource";
import { IBaseResult } from "../../../../models/baseResults";
import { HashMap } from "../../../../models/request-models/hashmap";

const AddFeedSource = () => {

    const rssFeedService = new RSSFeedService()
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(true);

    const [name, setName] = useState('')
    const [outlet, setOutlet] = useState('')
    const [metaData, setMetaData] = useState<HashMap<string, string>>();
    const [category, setCategory] = useState('')
    const [url, setUrl] = useState('')

    const uploadFeedSource = async (e: any) => {
        e.preventDefault()
        setLoading(true)

        const source = {
            feedID: '',
            feedName: name,
            newsOutlet: outlet,
            category: category,
            subCategories: [''],
            keywords: metaData,
            feedURL: url,
            dateAdded: 0
        } as IFeedSource

        let sources = [] as IFeedSource[]

        sources[0] = source

        await rssFeedService.addRssFeedSource(sources).then((response: IBaseResult<boolean>) =>{
            if(response.isSuccessful){
                setMessage('successfully uploaded source')
            }
            else{
                throw new Error(response.errorMessage)
            }
        }).catch((err: Error) =>{
            setMessage(err.message)
        }).finally(() =>{
            setLoading(false)
        })
    }

    return <>
        <div className="py-8">
            <header>
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">Submit Source</h1>
                    {message !== '' ? <>
                        <AlertError message={message} />
                    </> : <>
                    </>
                    }
                </div>
            </header>
            <form>
                <div className="space-y-12">
                    <div className="mt-10 grid grid-cols-8 gap-x-6 gap-y-8">
                        <div className="sm:col-span-4">
                            <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                                Name
                            </label>
                            <div className="mt-2">
                                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                    <input
                                        type="text"
                                        name="title"
                                        id="title"
                                        autoComplete="title"
                                        onChange={(e) => setName(e.target.value)}
                                        value={name}
                                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                        placeholder=""
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="sm:col-span-4">
                            <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                                Category
                            </label>
                            <div className="mt-2">
                                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                    <input
                                        type="text"
                                        name="title"
                                        id="title"
                                        autoComplete="title"
                                        onChange={(e) => setCategory(e.target.value)}
                                        value={category}
                                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                        placeholder=""
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="sm:col-span-4">
                            <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                                Outlet
                            </label>
                            <div className="mt-2">
                                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                    <input
                                        type="text"
                                        name="title"
                                        id="title"
                                        autoComplete="title"
                                        onChange={(e) => setOutlet(e.target.value)}
                                        value={outlet}
                                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                        placeholder=""
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="sm:col-span-4">
                            <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                                Feed URL
                            </label>
                            <div className="mt-2">
                                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                    <input
                                        type="text"
                                        name="title"
                                        id="title"
                                        autoComplete="title"
                                        onChange={(e) => setUrl(e.target.value)}
                                        value={url}
                                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                        placeholder=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button
                        type="button"
                        onClick={(e) => uploadFeedSource(e)}
                        className="mt-3 inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                    >
                        Add Source
                    </button>
            </form>
        </div>
    </>
}

export default AddFeedSource