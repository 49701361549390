// routeSlice.js
import { createSlice } from '@reduxjs/toolkit';

const routeSlice = createSlice({
    name: 'route',
    initialState: { currentRoute: '/' },
    reducers: {
        setRoute: (state, action) => {
            state.currentRoute = action.payload;
        },
    },
});

export const { setRoute } = routeSlice.actions;

export default routeSlice.reducer;