/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { Transition, Dialog, RadioGroup, Listbox } from "@headlessui/react";
import { XMarkIcon, StarIcon, ChevronRightIcon, PlusIcon, MinusIcon, CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { useCallback, useEffect, useState } from "react";
import { Fragment } from "react/jsx-runtime";
import { IArticleModel, IDailyNewspaperSubmission } from "../../../models/content-models/articleModels";
import ArticleCard from "./article-card";
import { ArticleService } from "../../../services/articleSerivce";
import Loading from "../shared/loading";
import { IBaseResult } from "../../../models/baseResults";

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

const categories = [
    {
        name: "MKE Post",
        value: "MKE-Post-Newspaper"
    }
]

const createRanking = ({ category }: any) => {

    const articleService = new ArticleService();
    const [selectedCategory, setSelectedCategory] = useState(categories[0]);
    const [articleList, setArticleList] = useState<IArticleModel[]>([]);
    const [rankingList, setRankingList] = useState<IArticleModel[]>([]);
    const [rankingIDs, setRankingIDs] = useState<string[]>([])
    const [submitResponse, setSubmitResponse] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [loading, setLoading] = useState<boolean>(true)

    const getArticle = async () => {
        const queue = `sort=desc&items=150&page=1`;
        await articleService.getArticleCollection(queue).then((response: IBaseResult<any>) => {
            if (response.isSuccessful) {
                setArticleList(response.results.pagedItems);
                setLoading(false);
            }
            else {
                setErrMsg(response.errorMessage)
            }
            setLoading(false);
        })
    }


    const UpdateSelected = (selectedMarquee: any) => {
        setSelectedCategory(selectedMarquee);
    }

    const submitRanking = async (e: any) => {
        e.preventDefault();

        const dailyNewspaper = {
            newspaperID: '',
            submissionDate: 0,
            articles: rankingIDs
        } as IDailyNewspaperSubmission;

        await articleService.submitNewspaper(selectedCategory.value, dailyNewspaper).then((response) => {
            if (response.isSuccessful) {
                setSubmitResponse('Successfully uploaded rankings');
            } else {
                setSubmitResponse('Failed to upload rankings');
            }
        })
    }

    const addArticle = (e: any, article: IArticleModel) => {
        e.preventDefault();
        //article.rank = rankingList.length + 1;
        setRankingList([...rankingList, article]);
        setRankingIDs([...rankingIDs, article.articleID])
    }

    const removeArticle = (e: any) => {
        e.preventDefault();
        setRankingList(rankingList => rankingList.slice(0, -1))
        setRankingIDs(rankingIDs => rankingIDs.slice(0, -1))
    }

    const initialArticlelist = useCallback(async () => {
        await getArticle();
    }, [])

    useEffect(() => {
    }, [rankingList])

    useEffect(() => {
        initialArticlelist();
    }, [initialArticlelist])

    return <>
        <div>
            <button
                type="button"
                className="flex items-end rounded-md bg-indigo-600 p-2 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={(e) => submitRanking(e)}
            >
                <p>Submit Ranking</p>
            </button>
            {!loading ? <>
                {submitResponse !== '' ? <>{submitResponse}</> : <></>}
                <div className="grid grid-cols-2">
                    <div>
                        {/* Static sidebar for desktop */}
                        <div className="">
                            {/* Sidebar component, swap this element with another sidebar if you like */}
                            <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
                                <div className=" shrink-0 items-center">
                                    Articles
                                </div>
                                <ul
                                    role="list"
                                    className="divide-y p-2 divide-gray-100 overflow-auto bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl"
                                >
                                    {articleList.map((article) => (
                                        <>
                                            <ArticleCard article={article} />
                                            <button
                                                type="button"
                                                className="flex items-end rounded-md bg-indigo-600 p-2 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                onClick={(e) => addArticle(e, article)}
                                            >
                                                <p>Add</p>
                                                <PlusIcon className="h-5 w-5" aria-hidden="true" />
                                            </button>
                                        </>

                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="relative inset-y-0 left-72 hidden w-96 overflow-y-auto border-r border-gray-200 px-4 sm:px-6 lg:px-8 xl:block">
                            Selected
                        </div>
                        <div className="ml-2">
                            {rankingList.map((article) => (
                                <>
                                    <ArticleCard article={article} />
                                </>
                            ))}
                            <button
                                type="button"
                                className="flex items-end rounded-md bg-red-300 p-2 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                onClick={(e) => removeArticle(e)}
                            >
                                <p>Remove Last Article</p>
                                <MinusIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                        </div>

                    </div>
                </div>


            </> : <>
                <Loading />
            </>}


        </div>
    </>
}

export default createRanking;