/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/rules-of-hooks */
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react/jsx-runtime";
import logo from "../components/shared/logo";
import { Navigate } from "react-router-dom";
import { CognitoService } from "../../services/cognitoService";
import { useEffect, useState } from "react";
import { IJournalistModel } from "../../models/content-models/journalistModels";
import { JournalistService } from "../../services/journalistService";
import { AuthUser } from "aws-amplify/auth";
import { IBaseResult } from "../../models/baseResults";
import { useDispatch, useSelector } from "react-redux";
import { updateJournalist } from "../../redux/slice/journalistSlice";
import { RootState } from "../../redux/store";

const adminRoutes = [
  {
    href: "/",
    name: "Dashboard",
  },
  {
    href: "/submit-article",
    name: "Submit Article",
  },
  {
    href: "/rankings",
    name: "Article Rankings",
  },
  {
    href: "/content-manager",
    name: "Content Manager",
  },
  {
    href: "/journalists",
    name: "Journalists",
  },
];

const journalistRoutes = [
  {
    href: "/",
    name: "Dashboard",
  },
  {
    href: "/submit-article",
    name: "Submit Article",
  },
  {
    href: "/my-articles",
    name: "My Articles",
  },
  {
    href: "/profile",
    name: "My Profile",
  },
];

const userNav = () => {
  const cognito = new CognitoService();
  const journalistService = new JournalistService();
  const journalist = useSelector(
    (state: RootState) => state.journalist.journalist
  );
  const dispatch = useDispatch();

  // get journalist
  const [error, setError] = useState("");

  const getJournalist = async () => {
    await cognito.getUser().then(async (user: AuthUser) => {
      console.log("journalist in nav: ", journalist);

      if (journalist.journalistID === "") {
        await journalistService
          .getJournalist("Email", user.signInDetails!.loginId!)
          .then((response: IBaseResult<IJournalistModel>) => {
            console.log("journalist", response);
            if (response.isSuccessful) {
              dispatch(updateJournalist(response.result));
            } else {
              setError(response.errorMessage);
            }
          });
      }
    });
  };

  const signOut = async () => {
    await cognito.signOut().then(async () => {
      window.localStorage.clear();
      window.location.reload();

      return <Navigate to="/" replace={true} />;
    });
  };

  useEffect(() => {
    getJournalist();
  }, []);

  return (
    <>
      <div className="flex">
        <div className="flex flex-shrink-0 items-center">{logo()}</div>
        <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
          {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
          {journalist?.roles.includes("admin") ? (
            <>
              {adminRoutes.map((route) => (
                <a
                  href={route.href}
                  className="inline-flex items-center hover:border-b-2 hover:border-indigo-500 px-1 pt-1 text-sm font-medium text-gray-900"
                >
                  {route.name}
                </a>
              ))}
            </>
          ) : journalist?.roles.includes("journalist") ? (
            <>
              {journalistRoutes.map((route) => (
                <a
                  href={route.href}
                  className="inline-flex items-center hover:border-b-2 hover:border-indigo-500 px-1 pt-1 text-sm font-medium text-gray-900"
                >
                  {route.name}
                </a>
              ))}
            </>
          ) : (
            <></>
          )}
          <></>
        </div>
      </div>
      <div className="hidden sm:ml-6 sm:flex sm:items-center">
        {/* Profile dropdown */}
        <Menu as="div" className="relative ml-3 z-50">
          <div>
            <Menu.Button className="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              <span className="absolute -inset-1.5" />
              <span className="sr-only">Open user menu</span>
              <img
                className="h-8 w-8 rounded-full"
                src={journalist?.image?.imageURL || ""}
                alt=""
              />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-50 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <Menu.Item>
                {({ active }) => (
                  <a
                    onClick={signOut}
                    className={classNames(
                      active ? "bg-gray-100" : "",
                      "block px-4 py-2 text-sm text-gray-700 overflow-show"
                    )}
                  >
                    Sign out
                  </a>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </>
  );
};

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default userNav;
