"use client";
import { useEffect, useRef, useState } from "react";
declare global {
  interface Window {
    adsbygoogle: unknown[];
  }
}

interface AdsBannerProps {
  "data-ad-slot": string;
  "data-ad-format": string;
  "data-full-width-responsive": string;
  "data-ad-layout"?: string;
}

const AdBanner = (props: AdsBannerProps) => {
  const elementRef = useRef<HTMLDivElement>(null); // Use a ref to reference the element
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 }); // Store dimensions

  useEffect(() => {
    const handleRouteChange = () => {
      const intervalId = setInterval(() => {
        try {
          // Check if the 'ins' element already has an ad in it
          if (window.adsbygoogle) {
            window.adsbygoogle.push({});
            clearInterval(intervalId);
          }
        } catch (err) {
          console.error("Error pushing ads: ", err);
          clearInterval(intervalId); // Ensure we clear interval on errors too
        }
      }, 100);
      return () => clearInterval(intervalId); // Clear interval on component unmount
    };

    // Run the function when the component mounts
    handleRouteChange();

    // Subscribe to route changes
  }, []);

  useEffect(() => {
    // Ensure the element exists before trying to access its dimensions
    if (elementRef.current) {
      const { width, height } = elementRef.current.getBoundingClientRect();
      setDimensions({ width, height });
    }
  }, []);

  return (
    <>
      <div ref={elementRef}>
        <p className="italic text-gray-600 text-xs">advertisement</p>
        <ins
          className="adsbygoogle adbanner-customize mt-2"
          style={{
            display: "block",
            overflow: "hidden",
            border: "1px solid red",
            background: "rgba(255, 0, 0, 0.1)",
          }}
          data-adtest="on"
          {...props}
        >
          <p>Width: {dimensions.width}px</p>
          <p>Height: {dimensions.height}px</p>
        </ins>
      </div>
    </>
  );
};
export default AdBanner;
