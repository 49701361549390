import React, { useCallback, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { CognitoService } from '../services/cognitoService';

const UserProtectedRoute = ({ children }: any) => {

    const cognito = new CognitoService();
    const navigate = useNavigate();
    const [validUser, setValidUser] = useState(false);

    const getCredentials = useCallback(async () => {
        await cognito.promiseAuthenticated().then((valid: boolean) => {
            setValidUser(valid);
            if (!valid) {
                // If there's no user, redirect to login page
                navigate('/sign-in')
            }
        });
    }, []);

    useEffect(() => {
        getCredentials();
      }, [validUser]);

    return children;
};

export default UserProtectedRoute;