const logo = () => {

    return <>
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
                    className="mx-auto h-10 w-auto"
                    src="/MILWAUKEE-POST-INLINE-2.png"
                />

        </div>
    </>
}

export default logo;