import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IArticleModel } from "../../models/content-models/articleModels";

interface ArticleState{
    article: IArticleModel
}

const localSubmitArticle = JSON.parse(localStorage.getItem('_submit_article') || '{}') as IArticleModel

const initialState: ArticleState = {
    article: {
        articleTitle: "",
        articleID: "",
        articleType: "Article",
        categories: [],
        subCategory: "",
        articleLead: "",
        content: undefined,
        plainText: "",
        image: {
            imageID: "",
            imageName: "",
            imageURL: "",
            uploadedBy: "",
            imageCaption: "",
            metaData: [],
            submissionDate: 0
        },
        journalist: {
            cognitoID: "",
            journalistID: "",
            email: "",
            firstName: "",
            lastName: "",
            middle: "",
            image: {
                imageID: "",
                imageName: "",
                imageURL: "",
                uploadedBy: "",
                imageCaption: "",
                metaData: [],
                submissionDate: 0
            },
            expertiseCategories: [],
            roles: [],
            sources: [],
            byline: "",
            phoneNumber: ""
        },
        premiumContent: false,
        sponsoredContent: false,
        submissionDate: 0,
        metaData: [],
        publisher: ""
    }
}

const articleSubmissionSlice = createSlice({
    name: "article",
    initialState,
    reducers: {
        updateArticleSubmission: (state, action: PayloadAction<any>) =>{
            state.article = action.payload
            localStorage.setItem('journalist', JSON.stringify(state.article))
        },
        removeArticleSubmission:(state) => {
            state.article = initialState.article;
            localStorage.removeItem('_submit_article')
        }
    }
})

export const { updateArticleSubmission, removeArticleSubmission } = articleSubmissionSlice.actions;

export default articleSubmissionSlice.reducer;