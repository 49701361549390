/* eslint-disable react-hooks/rules-of-hooks */
import { Fragment, useEffect, useState } from 'react'
import Podcast from './podcast/Podcast'
import Video from './video/Video'
import FeedSource from './feed-sources/FeedSource'
import ResendNewsletters from '../resend/resend-newsletters'

const tabs = [
  { name: 'Content', href: '/content-manager', current: true },
  { name: 'Newsletters', href: '/newsletters', current: false },
  { name: 'Podcasts', href: '/podcasts', current: false },
  { name: 'Feed Sources', href: '/feed-sources', current: false },
]

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const ContentManager = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [selectedTab, setSelectedTab] = useState('Content')

  const contentHome = () => {
    return <>Content</>
  }

  const renderTabs = () => {
    console.log('re render tabs')
    return <>
      {(() => {
        console.log(selectedTab)
        switch (selectedTab) {
          case 'Content':
            return contentHome()
          case 'Podcasts':
            return <Podcast />
          // case 'Videos':
          //   return <Video />
          case 'Newsletters' :
            return <ResendNewsletters />
          case 'Feed Sources':
            return <FeedSource />
        }
      })()}
    </>
  }

  useEffect(() => {
    console.log('tab switch')
  }, [selectedTab])

  return (
    <>
      <div className="container mx-auto sm:px-6 lg:px-8">
        <div className="border-b border-gray-200 pb-5 sm:pb-0">
          <div className="mt-3 sm:mt-4">
            <div className="sm:hidden">
              <label htmlFor="current-tab" className="sr-only">
                Select a tab
              </label>
              <select
                id="current-tab"
                name="current-tab"
                className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                defaultValue={tabs.find((tab) => tab.current)?.name}
              >
                {tabs.map((tab) => (
                  <option key={tab.name}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block">
              <nav className="-mb-px flex space-x-8">
                {tabs.map((tab) => (
                  <button
                    key={tab.name}
                    onClick={() => setSelectedTab(tab.name)}
                    className={classNames(
                      tab.name === selectedTab
                        ? 'border-indigo-500 text-indigo-600'
                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                      'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium'
                    )}
                    aria-current={tab.current ? 'page' : undefined}
                  >
                    {tab.name}
                  </button>
                ))}
              </nav>
            </div>
            {renderTabs()}
          </div>
        </div>
      </div>

    </>
  )
}

export default ContentManager;