/* eslint-disable react-hooks/rules-of-hooks */
import { useNavigate, useParams } from "react-router-dom";
import { ArticleService } from "../../../services/articleSerivce";

const QueueControls = () => {
    const articleService = new ArticleService();
    const navigate = useNavigate();
    const { articleId } = useParams();

    const confirmArticle = async (e: any) => {
        e.preventDefault();
        await articleService.confirmArticle(articleId!).then((response) =>{
            navigate('/')
        }).catch((err: any) =>{
        })
    }

    const rejectArticle = async (e: any) =>{
        e.preventDefault();
        await articleService.rejectArticle(articleId!).then((response) =>{
            navigate('/')
        }).catch((err: any) =>{
        })
    }

    return <>
        <div className="flex sticky top-0 z-50 bg-white border-t">
            <button
                type="button"
                className="rounded-md m-2 bg-red-200 px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-red-500"
                onClick={e => rejectArticle(e)}
            >
                Reject Article
            </button>
            <button
                type="button"
                className="rounded-md m-2 bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-green-600"
                onClick={e => confirmArticle(e)}
            >
                Approve Article
            </button>
        </div>
    </>
}

export default QueueControls