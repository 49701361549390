/* eslint-disable no-useless-concat */
import { IBaseResult } from "../models/baseResults";
import BaseService from "./baseService";

export class AuthService extends BaseService {
  
  public async getUserMetaData(): Promise<string>{
    let sessionStr = '';
    try{
      await text('https://www.cloudflare.com/cdn-cgi/trace').then((data: any) => {
        let ipRegex = /[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}/
        let ip = data.match(ipRegex)[0];
        let dataArr = data.split("\n");
        let tsLine = dataArr[3];
        let ts = tsLine.split("=");
        sessionStr = ip + '_' + ts[1];
      });
      return sessionStr;
    }
    catch(err){
      return 'xxx.xxx.xxx.xxx'
    }
  }
}

function text(url: string) {
  return fetch(url).then(res => res.text());
}