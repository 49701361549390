import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IJournalistModel } from "../../models/content-models/journalistModels";
import { IImageModel } from "../../models/content-models/imageModel";

interface ImageState{
    image: IImageModel
}

const localImage = JSON.parse(localStorage.getItem('image') || '{}') as IImageModel

const initialState: ImageState = {
    image: {
        imageID: "",
        imageName: "",
        imageURL: "",
        uploadedBy: "",
        imageCaption: "",
        metaData: [],
        submissionDate: 0
    }
}

const imageSlice = createSlice({
    name: "image",
    initialState,
    reducers: {
        updateImage: (state, action: PayloadAction<any>) =>{
            state.image = action.payload
            localStorage.setItem('image', JSON.stringify(state.image))
        }
    }
})

export const { updateImage } = imageSlice.actions;

export default imageSlice.reducer;