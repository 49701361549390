import { useEffect, useState } from "react";
import { IArticleModel } from "../../../models/content-models/articleModels";
import LoadingIndicator from "../shared/loading";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../redux/store";
import { JournalistService } from "../../../services/journalistService";
import { ArticleService } from "../../../services/articleSerivce";
import { IBaseResult } from "../../../models/baseResults";
import ArticleResults from "../shared/article-results";

const MyArticles = () => {
  const [sort, setSort] = useState("desc");
  const [page, setPage] = useState(1);
  const [items, setItems] = useState(15);

  const [articles, setArticles] = useState<IArticleModel[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [message, setMessage] = useState("");
  const articleService = new ArticleService();

  const journalist = useSelector(
    (state: RootState) => state.journalist?.journalist
  );
  const dispatch = useDispatch();

  console.log('journalist state', journalist)

  return (
    <>
      <div>
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
            My Published Articles
          </h2>
        </div>
            <ArticleResults keyword={"JournalistID"} value={journalist.journalistID} />
      </div>
    </>
  );
};

export default MyArticles;
