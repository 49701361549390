import { IBaseResult } from "../models/baseResults";
import { IFeedSource } from "../models/rss-models/FeedSource";
import BaseService from "./baseService";

export class RSSFeedService extends BaseService{
    async getRSSFeed(queryString: string): Promise<IBaseResult<any>>{
        return BaseService.GetData(`${process.env.REACT_APP_JOURNALISTS_API_URL}/api/rss/feed?${queryString}`)
    }

    async getRSSSources(queryString: string): Promise<IBaseResult<any>>{
        return BaseService.GetData(`${process.env.REACT_APP_JOURNALISTS_API_URL}/api/rss/source?${queryString}`)
    }

    async addRssFeedSource(sources: IFeedSource[]): Promise<IBaseResult<any>>{
        return BaseService.PostData(`${process.env.REACT_APP_JOURNALISTS_API_URL}/api/rss/source`, sources)
    }

    async updateRssFeedSource(source: IFeedSource): Promise<IBaseResult<any>>{
        return BaseService.PutData(`${process.env.REACT_APP_JOURNALISTS_API_URL}/api/rss/source`, source)
    } 

    async deletRssFeedSource(feedID: string): Promise<IBaseResult<any>>{
        return BaseService.DeleteData(`${process.env.REACT_APP_JOURNALISTS_API_URL}/api/rss/feed/${feedID}`)
    }
}