import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setRoute } from '../redux/slice/routeSlice';

const RouteWatcher = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        // Dispatch the route to Redux store when it changes
        dispatch(setRoute(location.pathname));
    }, [location, dispatch]);

    return null; // No UI needed, just listens to route changes
};

export default RouteWatcher